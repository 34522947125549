import React, {createContext, FC, ReactNode, useContext} from 'react';
import {useCookies} from 'react-cookie';
import {IS_PRODUCTION} from '@config/variables';


type UseIdToken = {
    idToken: string | undefined,
    hasIdToken: boolean
    setIdToken: (idToken: string) => void,
    destroyIdToken: () => void,
}

/**
 * Get, Set, Remove and check token.
 */
const useIdToken = (): UseIdToken => {

    /**
     *
     */
    const authCookieName = 'tjecko_auth_token';

    /**
     *
     */
    const [cookies, setCookie, removeCookie] = useCookies([authCookieName]);

    /**
     *
     */
    const cookieOptions = {
        path: '/',
        secure: IS_PRODUCTION,
        sameSite: 'strict' as boolean | 'strict' | 'none' | 'lax' | undefined
    };

    /**
     *
     */
    const idToken: string | undefined = cookies[authCookieName];

    /**
     *
     * @param idToken
     */
    const setIdToken = (idToken?: string): void => setCookie(authCookieName, idToken, cookieOptions);

    /**
     *
     */
    const destroyIdToken = (): void => removeCookie(authCookieName);

    /**
     *
     */
    const hasIdToken = idToken !== undefined;


    return {
        idToken,
        hasIdToken,
        setIdToken,
        destroyIdToken,
    };
};

export default useIdToken;


type IdTokenProviderProps = {
    children: ReactNode;
}

/**
 * Set token context.
 */
const IdTokenCtx = createContext<string | undefined>(undefined);


/**
 * Provider for application wide access.
 * @param children
 * @constructor
 */
export const IdTokenProvider: FC<IdTokenProviderProps> = ({children}) => {
    const {idToken} = useIdToken();

    return (
        <IdTokenCtx.Provider value={idToken}>
            {children}
        </IdTokenCtx.Provider>
    );
};


/**
 * Get up-to-date value application wide.
 */
export const useIdTokenCtx = () => useContext(IdTokenCtx);



