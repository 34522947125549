/* eslint-disable no-use-before-define, @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars */
// noinspection NestedFunctionCallJS, JSUnusedGlobalSymbols, GraphQLUnresolvedReference

import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddressModel = {
  __typename?: 'AddressModel';
  address: Scalars['String']['output'];
  addressType: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  extra: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AmountType = {
  Value: Scalars['String']['input'];
  currency: Scalars['String']['input'];
};

export type Analytics = {
  __typename?: 'Analytics';
  dayOfMonthStats: Array<Maybe<DayOfMonthStats>>;
  flowMetrics: Array<Maybe<FlowMetrics>>;
  growthMetrics: GrowthMetrics;
  invoiceStatistics: Array<Maybe<InvoiceStatistics>>;
  months: Scalars['Int']['output'];
  paymentTimings: Array<Maybe<PaymentTimings>>;
  revenueMetrics: Array<Maybe<RevenueMetrics>>;
  tallies: Array<Maybe<Tallies>>;
  weekdayStats: Array<Maybe<WeekdayStats>>;
};

export type BaseModel = {
  __typename?: 'BaseModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BenefitModel = {
  __typename?: 'BenefitModel';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  subscriptionId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClauseAgeModel = {
  __typename?: 'ClauseAgeModel';
  active: Scalars['Boolean']['output'];
  amount: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  clauseType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabledTill?: Maybe<Scalars['DateTime']['output']>;
  enabledTill?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  subscription: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClauseDynamicModel = {
  __typename?: 'ClauseDynamicModel';
  active: Scalars['Boolean']['output'];
  amount: Scalars['Float']['output'];
  amountMax: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  clauseType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabledTill?: Maybe<Scalars['DateTime']['output']>;
  enabledTill?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  interval: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  subscription: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClauseFixedModel = {
  __typename?: 'ClauseFixedModel';
  active: Scalars['Boolean']['output'];
  amount: Scalars['Float']['output'];
  amountType: Scalars['String']['output'];
  clauseType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabledTill?: Maybe<Scalars['DateTime']['output']>;
  enabledTill?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  interval: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  period: Scalars['String']['output'];
  subscription: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CocInfoModel = {
  __typename?: 'CocInfoModel';
  addressId?: Maybe<Scalars['ID']['output']>;
  coc?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyModel>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vat?: Maybe<Scalars['String']['output']>;
};

export type CompanyFormInput = {
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceRecipient?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyModel = {
  __typename?: 'CompanyModel';
  addresses?: Maybe<Array<Maybe<AddressModel>>>;
  cocInfo?: Maybe<CocInfoModel>;
  contacts?: Maybe<Array<Maybe<ContactModel>>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invoiceEmail: Scalars['String']['output'];
  invoiceRecipient: Scalars['String']['output'];
  name: Scalars['String']['output'];
  theme?: Maybe<MerchantThemeModel>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ConditionModel = {
  __typename?: 'ConditionModel';
  condition?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactModel = {
  __typename?: 'ContactModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreateAddressInput = {
  address: Scalars['String']['input'];
  addressType: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company: Scalars['String']['input'];
  extra: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
};

export type CreateBenefitForTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateBenefitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateClauseAgeInput = {
  active: Scalars['Boolean']['input'];
  amount: Scalars['Float']['input'];
  amountType: Scalars['String']['input'];
  clauseType: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  order: Scalars['Int']['input'];
  subscription: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateClauseDynamicInput = {
  active: Scalars['Boolean']['input'];
  amount: Scalars['Float']['input'];
  amountMax: Scalars['Float']['input'];
  amountType: Scalars['String']['input'];
  clauseType: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  subscription: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateClauseFixedInput = {
  active: Scalars['Boolean']['input'];
  amount: Scalars['Float']['input'];
  amountType: Scalars['String']['input'];
  clauseType: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  period: Scalars['String']['input'];
  subscription: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateCocInfoInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  coc?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyInput = {
  invoiceEmail: Scalars['String']['input'];
  invoiceRecipient: Scalars['String']['input'];
  name: Scalars['String']['input'];
  user?: InputMaybe<Scalars['String']['input']>;
};

export type CreateConditionInput = {
  condition?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type CreateDiscountCodeInput = {
  availableAmount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount: Scalars['Float']['input'];
  discountType: Scalars['String']['input'];
  title: Scalars['String']['input'];
  useBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateExtraInput = {
  active: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['Float']['input'];
  when: Scalars['String']['input'];
};

export type CreateMandateRequest = {
  companyID: Scalars['ID']['input'];
  consumerAccount: Scalars['String']['input'];
  consumerBic: Scalars['String']['input'];
  consumerName: Scalars['String']['input'];
  method: Scalars['String']['input'];
  signatureDate?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMerchantThemeInput = {
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  isFormal?: InputMaybe<Scalars['Boolean']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMetricTypeInput = {
  tierId: Scalars['ID']['input'];
  typeName: Scalars['String']['input'];
};

export type CreateMetricValueInput = {
  amount: Scalars['Int']['input'];
  time: Scalars['DateTime']['input'];
  typeId: Scalars['ID']['input'];
};

export type CreateNotificationInput = {
  forSubscriptionId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  scheduledAt: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type CreatePaymentRequest = {
  amount: AmountType;
  customerId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  mandateId: Scalars['ID']['input'];
  sequenceType?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePromotionInput = {
  active: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  order: Scalars['Int']['input'];
  subscription: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateSubscriptionInput = {
  billingDateType: Scalars['Int']['input'];
  billingDayOfMonth: Scalars['Int']['input'];
  billingDayOfWeek: Scalars['String']['input'];
  billingPartOfMonth: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  vatPercentage: Scalars['Float']['input'];
};

export type CreateSubscriptionIntervalInput = {
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType: Scalars['String']['input'];
  interval: Scalars['String']['input'];
  subscriptionId: Scalars['ID']['input'];
};

export type CreateTermsInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateTierBenefitInput = {
  benefitId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
};

export type CreateTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  subscriptionId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateUnitPricingInput = {
  cents: Scalars['Int']['input'];
  metricId: Scalars['ID']['input'];
  threshold: Scalars['Int']['input'];
  units: Scalars['Int']['input'];
};

export type CreateUserInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  paymentMethod?: InputMaybe<PaymentMethod>;
  phone: Scalars['String']['input'];
};

export type CurrentRegistrationStepOfMerchant = {
  __typename?: 'CurrentRegistrationStepOfMerchant';
  currentStep: Scalars['String']['output'];
};

export type DayOfMonthStats = {
  __typename?: 'DayOfMonthStats';
  date: Scalars['DateTime']['output'];
  days?: Maybe<Array<Scalars['Int']['output']>>;
};

export type DeleteMandateRequest = {
  customer: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type DiscountCodeModel = {
  __typename?: 'DiscountCodeModel';
  availableAmount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount: Scalars['Float']['output'];
  discountType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useBefore?: Maybe<Scalars['DateTime']['output']>;
};

export type ExtraModel = {
  __typename?: 'ExtraModel';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  subscriptionId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
  when: Scalars['String']['output'];
};

export type FlowMetrics = {
  __typename?: 'FlowMetrics';
  attrition: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  gain: Scalars['Int']['output'];
  retention: Scalars['Int']['output'];
};

export type GrowthMetrics = {
  __typename?: 'GrowthMetrics';
  averageSubLength: Scalars['Int']['output'];
  highestGrossingCustomer?: Maybe<UserModel>;
  highestGrossingCustomerAmount: Scalars['Int']['output'];
  highestGrossingSub?: Maybe<Scalars['ID']['output']>;
  highestGrossingSubAmount: Scalars['Int']['output'];
  highestGrossingTier?: Maybe<Scalars['ID']['output']>;
  highestGrossingTierAmount: Scalars['Int']['output'];
  lastSub?: Maybe<Scalars['DateTime']['output']>;
  longestSub?: Maybe<UserModel>;
  lowestGrossingSub?: Maybe<Scalars['ID']['output']>;
  lowestGrossingSubAmount: Scalars['Int']['output'];
  lowestGrossingTier?: Maybe<Scalars['ID']['output']>;
  lowestGrossingTierAmount: Scalars['Int']['output'];
};

export type Identity = {
  __typename?: 'Identity';
  domain: Scalars['String']['output'];
  user: User;
};

export type InvoiceStatistics = {
  __typename?: 'InvoiceStatistics';
  date: Scalars['DateTime']['output'];
  dayPaid: WeekdayStats;
  expired: Scalars['Int']['output'];
  failed: Scalars['Int']['output'];
  invoices: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
  retries: Scalars['Int']['output'];
  success: Scalars['Int']['output'];
};

export type Mandate = {
  __typename?: 'Mandate';
  consumerAccount?: Maybe<Scalars['String']['output']>;
  consumerBic?: Maybe<Scalars['String']['output']>;
  consumerName?: Maybe<Scalars['String']['output']>;
  mandateReference?: Maybe<Scalars['String']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  signatureDate?: Maybe<Scalars['String']['output']>;
};

export type MerchantOnboardingProgress = {
  __typename?: 'MerchantOnboardingProgress';
  addressIsComplete: Scalars['Boolean']['output'];
  companyIsComplete: Scalars['Boolean']['output'];
  contactIsComplete: Scalars['Boolean']['output'];
  memberIsComplete: Scalars['Boolean']['output'];
  paymentIsComplete: Scalars['Boolean']['output'];
  paymentMethodIsComplete: Scalars['Boolean']['output'];
  planIsComplete: Scalars['Boolean']['output'];
  profileIsComplete: Scalars['Boolean']['output'];
  subscriptionIsComplete: Scalars['Boolean']['output'];
  tosIsComplete: Scalars['Boolean']['output'];
};

export type MerchantProfileProgress = {
  __typename?: 'MerchantProfileProgress';
  companyId: Scalars['ID']['output'];
  complete: Scalars['Boolean']['output'];
  onboardingProgress: MerchantOnboardingProgress;
};

export type MerchantRegistrationInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  coc: Scalars['String']['input'];
  extra: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantRegistrationItem = {
  __typename?: 'MerchantRegistrationItem';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  coc: Scalars['String']['output'];
  extra: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  vat?: Maybe<Scalars['String']['output']>;
};

export type MerchantThemeModel = {
  __typename?: 'MerchantThemeModel';
  companyLogo?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isFormal?: Maybe<Scalars['Boolean']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MerchantTjeckoSubStatus = {
  __typename?: 'MerchantTjeckoSubStatus';
  initialPaymentComplete: Scalars['Boolean']['output'];
  paidLatest: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
};

export type MetricTypeModel = {
  __typename?: 'MetricTypeModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  tierId: Scalars['ID']['output'];
  typeName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MetricValueModel = {
  __typename?: 'MetricValueModel';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  time: Scalars['DateTime']['output'];
  typeId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MollieAuthorization = {
  __typename?: 'MollieAuthorization';
  authorizeMollieId?: Maybe<Scalars['String']['output']>;
  isAuthorized?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsForUser?: Maybe<Scalars['Boolean']['output']>;
  addAddressToCompany?: Maybe<Scalars['Boolean']['output']>;
  addAddressToUser?: Maybe<Scalars['Boolean']['output']>;
  addBenefitToTier?: Maybe<Scalars['Boolean']['output']>;
  addCocToCompany?: Maybe<Scalars['Boolean']['output']>;
  addCompanyToUser?: Maybe<Scalars['Boolean']['output']>;
  addContactToCompany?: Maybe<Scalars['Boolean']['output']>;
  addExtraToTier: Scalars['Boolean']['output'];
  addImageToCoc?: Maybe<Scalars['Boolean']['output']>;
  addMandate?: Maybe<Scalars['Boolean']['output']>;
  addPayment?: Maybe<Scalars['Boolean']['output']>;
  addThemeToCompany?: Maybe<Scalars['Boolean']['output']>;
  addTierToShoppingCart?: Maybe<Scalars['Boolean']['output']>;
  addTierToUser: Scalars['String']['output'];
  completeTierPurchase: Scalars['ID']['output'];
  createAddress: AddressModel;
  createBenefit: BenefitModel;
  createBenefitForTier: BenefitModel;
  createClauseAge: ClauseAgeModel;
  createClauseDynamic: ClauseDynamicModel;
  createClauseFixed: ClauseFixedModel;
  createCocInfo: CocInfoModel;
  createCompany: CompanyModel;
  createCondition: ConditionModel;
  createContact: ContactModel;
  createDiscountCode: DiscountCodeModel;
  createExtra: ExtraModel;
  createMandateVerifyUrl: PaymentUrl;
  createMerchantTheme: MerchantThemeModel;
  createMetricType: MetricTypeModel;
  createMetricValue: MetricValueModel;
  createNotification: NotificationModel;
  createPromotion: PromotionModel;
  createSubscription: SubscriptionModel;
  createSubscriptionInterval: SubscriptionIntervalModel;
  createTerms: TermsModel;
  createTier: TierModel;
  createUnitPricing: UnitPricingModel;
  createUser: UserModel;
  deleteAddress: Scalars['Boolean']['output'];
  deleteBenefit: Scalars['Boolean']['output'];
  deleteClauseAge: Scalars['Boolean']['output'];
  deleteClauseDynamic: Scalars['Boolean']['output'];
  deleteClauseFixed: Scalars['Boolean']['output'];
  deleteCocInfo: Scalars['Boolean']['output'];
  deleteCompany: Scalars['Boolean']['output'];
  deleteCondition: Scalars['Boolean']['output'];
  deleteContact: Scalars['Boolean']['output'];
  deleteDiscountCode: Scalars['Boolean']['output'];
  deleteExtra: Scalars['Boolean']['output'];
  deleteMandate?: Maybe<Scalars['Boolean']['output']>;
  deleteMerchantTheme: Scalars['Boolean']['output'];
  deleteMetricType: Scalars['Boolean']['output'];
  deleteMetricValue: Scalars['Boolean']['output'];
  deleteNotification: Scalars['Boolean']['output'];
  deletePromotion: Scalars['Boolean']['output'];
  deleteSubscription: Scalars['Boolean']['output'];
  deleteSubscriptionInterval: Scalars['Boolean']['output'];
  deleteTier: Scalars['Boolean']['output'];
  deleteUnitPricing: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  getPendingPurchases?: Maybe<Array<Maybe<PendingPurchase>>>;
  registerMerchant: MerchantRegistrationItem;
  registerMerchantContact?: Maybe<UserRegistrationItem>;
  removeAddressFromCompany?: Maybe<Scalars['Boolean']['output']>;
  removeAddressFromUser?: Maybe<Scalars['Boolean']['output']>;
  removeBenefitForTier?: Maybe<Scalars['Boolean']['output']>;
  removeCocFromCompany?: Maybe<Scalars['Boolean']['output']>;
  removeCompanyFromUser?: Maybe<Scalars['Boolean']['output']>;
  removeContactFromCompany?: Maybe<Scalars['Boolean']['output']>;
  removeImageFromCoc?: Maybe<Scalars['Boolean']['output']>;
  removeThemeFromCompany?: Maybe<Scalars['Boolean']['output']>;
  removeTierFromShoppingCart?: Maybe<Scalars['Boolean']['output']>;
  setActiveMerchant?: Maybe<Scalars['Boolean']['output']>;
  unacceptTermsForUser?: Maybe<Scalars['Boolean']['output']>;
  updateAddress: AddressModel;
  updateBenefit: BenefitModel;
  updateClauseAge: ClauseAgeModel;
  updateClauseDynamic: ClauseDynamicModel;
  updateClauseFixed: ClauseFixedModel;
  updateCocInfo: CocInfoModel;
  updateCompany: CompanyModel;
  updateCondition: ConditionModel;
  updateContact: ContactModel;
  updateDiscountCode: DiscountCodeModel;
  updateExtra: ExtraModel;
  updateMerchantTheme: MerchantThemeModel;
  updateMetricType: MetricTypeModel;
  updateMetricValue: MetricValueModel;
  updatePromotion: PromotionModel;
  updateSubscription: SubscriptionModel;
  updateSubscriptionInterval: SubscriptionIntervalModel;
  updateTerms: TermsModel;
  updateTier: TierModel;
  updateUnitPricing: UnitPricingModel;
  updateUser: UserModel;
};


export type MutationAcceptTermsForUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddAddressToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddAddressToUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddBenefitToTierArgs = {
  benefitId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
};


export type MutationAddCocToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddCompanyToUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddContactToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddExtraToTierArgs = {
  extraId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
};


export type MutationAddImageToCocArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddMandateArgs = {
  input: CreateMandateRequest;
};


export type MutationAddPaymentArgs = {
  input: CreatePaymentRequest;
};


export type MutationAddThemeToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddTierToShoppingCartArgs = {
  input: ShoppingCartInput;
};


export type MutationAddTierToUserArgs = {
  discount?: InputMaybe<Scalars['ID']['input']>;
  interval: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationCompleteTierPurchaseArgs = {
  mollieId: Scalars['ID']['input'];
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateBenefitArgs = {
  input: CreateBenefitInput;
};


export type MutationCreateBenefitForTierArgs = {
  input: CreateBenefitForTierInput;
};


export type MutationCreateClauseAgeArgs = {
  input: CreateClauseAgeInput;
};


export type MutationCreateClauseDynamicArgs = {
  input: CreateClauseDynamicInput;
};


export type MutationCreateClauseFixedArgs = {
  input: CreateClauseFixedInput;
};


export type MutationCreateCocInfoArgs = {
  input: CreateCocInfoInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateConditionArgs = {
  input: CreateConditionInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateDiscountCodeArgs = {
  input: CreateDiscountCodeInput;
};


export type MutationCreateExtraArgs = {
  input: CreateExtraInput;
};


export type MutationCreateMandateVerifyUrlArgs = {
  input: VerifyMandate;
};


export type MutationCreateMerchantThemeArgs = {
  input: CreateMerchantThemeInput;
};


export type MutationCreateMetricTypeArgs = {
  input: CreateMetricTypeInput;
};


export type MutationCreateMetricValueArgs = {
  input: CreateMetricValueInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateSubscriptionIntervalArgs = {
  input: CreateSubscriptionIntervalInput;
};


export type MutationCreateTermsArgs = {
  input: CreateTermsInput;
};


export type MutationCreateTierArgs = {
  input: CreateTierInput;
};


export type MutationCreateUnitPricingArgs = {
  input: CreateUnitPricingInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBenefitArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteClauseAgeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteClauseDynamicArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteClauseFixedArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCocInfoArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteConditionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDiscountCodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteExtraArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMandateArgs = {
  input?: InputMaybe<DeleteMandateRequest>;
};


export type MutationDeleteMerchantThemeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMetricTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMetricValueArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePromotionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSubscriptionIntervalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUnitPricingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRegisterMerchantArgs = {
  input: MerchantRegistrationInput;
};


export type MutationRegisterMerchantContactArgs = {
  input?: InputMaybe<UserRegistrationInput>;
};


export type MutationRemoveAddressFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveAddressFromUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveBenefitForTierArgs = {
  benefitId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
};


export type MutationRemoveCocFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveCompanyFromUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveContactFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveImageFromCocArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveThemeFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveTierFromShoppingCartArgs = {
  input: ShoppingCartInput;
};


export type MutationSetActiveMerchantArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnacceptTermsForUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationUpdateAddressArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAddressInput;
};


export type MutationUpdateBenefitArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBenefitInput;
};


export type MutationUpdateClauseAgeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateClauseAgeInput;
};


export type MutationUpdateClauseDynamicArgs = {
  id: Scalars['ID']['input'];
  input: UpdateClauseDynamicInput;
};


export type MutationUpdateClauseFixedArgs = {
  id: Scalars['ID']['input'];
  input: UpdateClauseFixedInput;
};


export type MutationUpdateCocInfoArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCocInfoInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCompanyInput;
};


export type MutationUpdateConditionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateConditionInput;
};


export type MutationUpdateContactArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContactInput;
};


export type MutationUpdateDiscountCodeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDiscountCodeInput;
};


export type MutationUpdateExtraArgs = {
  id: Scalars['ID']['input'];
  input: UpdateExtraInput;
};


export type MutationUpdateMerchantThemeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMerchantThemeInput;
};


export type MutationUpdateMetricTypeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMetricTypeInput;
};


export type MutationUpdateMetricValueArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMetricValueInput;
};


export type MutationUpdatePromotionArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePromotionInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSubscriptionInput;
};


export type MutationUpdateSubscriptionIntervalArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSubscriptionIntervalInput;
};


export type MutationUpdateTermsArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTermsInput;
};


export type MutationUpdateTierArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTierInput;
};


export type MutationUpdateUnitPricingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUnitPricingInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
};

export type NotificationModel = {
  __typename?: 'NotificationModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  forSubscriptionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  scheduledAt: Scalars['DateTime']['output'];
  sent: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum PaymentMethod {
  CreditCard = 'CREDIT_CARD',
  Sepa = 'SEPA'
}

export type PaymentTimings = {
  __typename?: 'PaymentTimings';
  average: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  maximum: Scalars['Int']['output'];
  median: Scalars['Int']['output'];
  minimum: Scalars['Int']['output'];
};

export type PaymentUrl = {
  __typename?: 'PaymentUrl';
  url: Scalars['String']['output'];
};

export type PendingPurchase = {
  __typename?: 'PendingPurchase';
  amount: Scalars['Float']['output'];
  checkoutUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  tier: TierModel;
};

export type PromotionModel = {
  __typename?: 'PromotionModel';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabledTill?: Maybe<Scalars['DateTime']['output']>;
  enabledTill?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  subscription: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<AddressModel>;
  addresses?: Maybe<Array<Maybe<AddressModel>>>;
  analytics: Analytics;
  benefit?: Maybe<BenefitModel>;
  benefits?: Maybe<Array<Maybe<BenefitModel>>>;
  benefitsBySubscriptionId?: Maybe<Array<Maybe<BenefitModel>>>;
  benefitsBySubscriptionIdAndTierId?: Maybe<Array<Maybe<BenefitModel>>>;
  benefitsByTierId?: Maybe<Array<Maybe<BenefitModel>>>;
  clauseAge?: Maybe<ClauseAgeModel>;
  clauseDynamic?: Maybe<ClauseDynamicModel>;
  clauseFixed?: Maybe<ClauseFixedModel>;
  clausesAge?: Maybe<Array<Maybe<ClauseAgeModel>>>;
  clausesDynamic?: Maybe<Array<Maybe<ClauseDynamicModel>>>;
  clausesFixed?: Maybe<Array<Maybe<ClauseFixedModel>>>;
  companies?: Maybe<Array<Maybe<CompanyModel>>>;
  company?: Maybe<CompanyModel>;
  condition?: Maybe<ConditionModel>;
  conditions?: Maybe<Array<Maybe<ConditionModel>>>;
  contact?: Maybe<ContactModel>;
  contacts?: Maybe<Array<Maybe<ContactModel>>>;
  discountCode?: Maybe<DiscountCodeModel>;
  discountCodes?: Maybe<Array<Maybe<DiscountCodeModel>>>;
  extra?: Maybe<ExtraModel>;
  extras?: Maybe<Array<Maybe<ExtraModel>>>;
  extrasBySubscriptionId?: Maybe<Array<Maybe<ExtraModel>>>;
  getCurrentMerchant?: Maybe<CompanyModel>;
  getMerchantRegistrationItem: MerchantRegistrationItem;
  getMerchantTjeckoSubStatus: MerchantTjeckoSubStatus;
  getShoppingCart?: Maybe<Array<Maybe<ShoppingCart>>>;
  getTjeckoTier: TjeckoTier;
  getUserRegistrationItem?: Maybe<UserRegistrationItem>;
  identities: Identity;
  latestTerms?: Maybe<TermsModel>;
  merchantOnboarding?: Maybe<MerchantOnboardingProgress>;
  merchantProfile?: Maybe<MerchantProfileProgress>;
  merchantRegistrationProgress?: Maybe<CurrentRegistrationStepOfMerchant>;
  merchantTheme?: Maybe<MerchantThemeModel>;
  merchantThemes?: Maybe<Array<Maybe<MerchantThemeModel>>>;
  metricTypes?: Maybe<Array<Maybe<MetricTypeModel>>>;
  metricValues?: Maybe<Array<Maybe<MetricValueModel>>>;
  mollieToken?: Maybe<MollieAuthorization>;
  notification?: Maybe<NotificationModel>;
  notifications?: Maybe<Array<Maybe<NotificationModel>>>;
  promotion?: Maybe<PromotionModel>;
  promotions?: Maybe<Array<Maybe<PromotionModel>>>;
  subscription?: Maybe<SubscriptionModel>;
  subscriptionInterval?: Maybe<SubscriptionIntervalModel>;
  subscriptionIntervals?: Maybe<Array<Maybe<SubscriptionIntervalModel>>>;
  subscriptions?: Maybe<Array<Maybe<SubscriptionModel>>>;
  terms?: Maybe<Array<Maybe<TermsModel>>>;
  tier?: Maybe<TierModel>;
  tiers?: Maybe<Array<Maybe<TierModel>>>;
  tiersBySubscriptionId?: Maybe<Array<Maybe<TierModel>>>;
  tjeckoSubscription: TjeckoSub;
  unitPricings?: Maybe<Array<Maybe<UnitPricingModel>>>;
  user?: Maybe<UserModel>;
  userOnboarding?: Maybe<UserOnboardingProgress>;
  userProfile?: Maybe<UserProfileProgress>;
  users?: Maybe<Array<Maybe<UserModel>>>;
};


export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAddressesArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAnalyticsArgs = {
  months: Scalars['Int']['input'];
};


export type QueryBenefitArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBenefitsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBenefitsBySubscriptionIdArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type QueryBenefitsBySubscriptionIdAndTierIdArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
};


export type QueryBenefitsByTierIdArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  tierId: Scalars['ID']['input'];
};


export type QueryClauseAgeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClauseDynamicArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClauseFixedArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClausesAgeArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryClausesDynamicArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryClausesFixedArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompaniesArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConditionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConditionsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDiscountCodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDiscountCodesArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExtraArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExtrasArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExtrasBySubscriptionIdArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type QueryGetMerchantRegistrationItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUserRegistrationItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLatestTermsArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMerchantOnboardingArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryMerchantProfileArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMerchantThemeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMerchantThemesArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMetricTypesArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMetricValuesArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPromotionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPromotionsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubscriptionIntervalArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubscriptionIntervalsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySubscriptionsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTermsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  companyId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTiersArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTiersBySubscriptionIdArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type QueryUnitPricingsArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserOnboardingArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserProfileArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  Page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCompanyInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  invoiceEmail: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  vat: Scalars['String']['input'];
};

export type RegisterMerchantInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type RevenueMetrics = {
  __typename?: 'RevenueMetrics';
  afterFees: Scalars['Float']['output'];
  beforeFees: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
  fees: Scalars['Float']['output'];
  invoices: Scalars['Int']['output'];
};

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  IntervalID: Scalars['ID']['output'];
  TierID: Scalars['ID']['output'];
  UserID: Scalars['ID']['output'];
};

export type ShoppingCartInput = {
  IntervalID: Scalars['ID']['input'];
  TierID: Scalars['ID']['input'];
  UserID: Scalars['ID']['input'];
};

export type SubscriptionIntervalModel = {
  __typename?: 'SubscriptionIntervalModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: Scalars['String']['output'];
  subscriptionId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionModel = {
  __typename?: 'SubscriptionModel';
  billingDateType: Scalars['Int']['output'];
  billingDayOfMonth: Scalars['Int']['output'];
  billingDayOfWeek: Scalars['String']['output'];
  billingPartOfMonth: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vatPercentage: Scalars['Float']['output'];
};

export type Tallies = {
  __typename?: 'Tallies';
  cancellations: Scalars['Int']['output'];
  customers: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  payments: Scalars['Int']['output'];
};

export type TermsModel = {
  __typename?: 'TermsModel';
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TierHasBenefitModel = {
  __typename?: 'TierHasBenefitModel';
  benefit: BenefitModel;
  benefitId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  tier: TierModel;
  tierId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TierModel = {
  __typename?: 'TierModel';
  TierHasBenefits: Array<Maybe<BenefitModel>>;
  TierHasClauses: Array<Maybe<ClauseFixedModel>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extends?: Maybe<Scalars['ID']['output']>;
  frequency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  subscription?: Maybe<SubscriptionModel>;
  subscriptionId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TjeckoSub = {
  __typename?: 'TjeckoSub';
  id: Scalars['ID']['output'];
  intervals?: Maybe<Array<Maybe<SubscriptionIntervalModel>>>;
  tiers?: Maybe<Array<Maybe<TierModel>>>;
  title: Scalars['String']['output'];
  vatPercentage: Scalars['Float']['output'];
};

export type TjeckoTier = {
  __typename?: 'TjeckoTier';
  id: Scalars['ID']['output'];
  interval: SubscriptionIntervalModel;
  tier: TierModel;
  title: Scalars['String']['output'];
  vatPercentage: Scalars['Float']['output'];
};

export type UnitPricingModel = {
  __typename?: 'UnitPricingModel';
  cents: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metricId: Scalars['ID']['output'];
  threshold: Scalars['Int']['output'];
  units: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UpdateAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBenefitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClauseAgeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  clauseType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClauseDynamicInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountMax?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  clauseType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClauseFixedInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountType?: InputMaybe<Scalars['String']['input']>;
  clauseType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCocInfoInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  coc?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyInput = {
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  invoiceRecipient?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConditionInput = {
  condition?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDiscountCodeInput = {
  availableAmount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  useBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateExtraInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  when?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMerchantThemeInput = {
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  isFormal?: InputMaybe<Scalars['Boolean']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMetricTypeInput = {
  typeName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMetricValueInput = {
  amount: Scalars['Int']['input'];
};

export type UpdatePromotionInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  enabledTill?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSubscriptionInput = {
  billingDateType?: InputMaybe<Scalars['Int']['input']>;
  billingDayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  billingDayOfWeek?: InputMaybe<Scalars['String']['input']>;
  billingPartOfMonth?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vatPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSubscriptionIntervalInput = {
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTermsInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUnitPricingInput = {
  cents?: InputMaybe<Scalars['Int']['input']>;
  threshold?: InputMaybe<Scalars['Int']['input']>;
  units?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latestTerms: Scalars['String']['output'];
  mfaEnabled: Scalars['Boolean']['output'];
  mfaVerified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type UserModel = {
  __typename?: 'UserModel';
  address?: Maybe<Array<Maybe<AddressModel>>>;
  companies?: Maybe<Array<Maybe<CompanyModel>>>;
  companyId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  eol?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  mfaEnabled: Scalars['Boolean']['output'];
  mfaVerified: Scalars['Boolean']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  secret: Scalars['String']['output'];
  subscriptionTiers?: Maybe<Array<Maybe<TierModel>>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserOnboardingProgress = {
  __typename?: 'UserOnboardingProgress';
  addressIsComplete: Scalars['Boolean']['output'];
  companyIsComplete: Scalars['Boolean']['output'];
};

export type UserProfileProgress = {
  __typename?: 'UserProfileProgress';
  complete: Scalars['Boolean']['output'];
  onboardingProgress: UserOnboardingProgress;
  userId: Scalars['ID']['output'];
};

export type UserRegistrationInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  extra: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type UserRegistrationItem = {
  __typename?: 'UserRegistrationItem';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  email: Scalars['String']['output'];
  extra: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type VerifyMandate = {
  mandateId: Scalars['ID']['input'];
};

export type WeekdayStats = {
  __typename?: 'WeekdayStats';
  date: Scalars['DateTime']['output'];
  friday: Scalars['Int']['output'];
  monday: Scalars['Int']['output'];
  saturday: Scalars['Int']['output'];
  sunday: Scalars['Int']['output'];
  thursday: Scalars['Int']['output'];
  tuesday: Scalars['Int']['output'];
  wednesday: Scalars['Int']['output'];
};

export type RegisterCompanyFormFragment = { __typename?: 'CompanyModel', name: string, invoiceEmail: string, cocInfo?: { __typename?: 'CocInfoModel', id: string, vat?: string }, addresses?: Array<{ __typename?: 'AddressModel', id: string, address: string, postalCode: string, city: string }> };

export type CompanyFormFragment = { __typename?: 'CompanyModel', name: string, invoiceEmail: string, invoiceRecipient: string };

export type BaseCompanyFragment = { __typename?: 'CompanyModel', id: string, name: string, invoiceEmail: string, invoiceRecipient: string, createdAt: any, updatedAt: any };

export type BaseMerchantThemeFragment = { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo?: string, primaryColor?: string, secondaryColor?: string, isFormal?: boolean };

export type BaseAddressFragment = { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string };

export type BaseContactFragment = { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string };

export type MinimumTosFragment = { __typename?: 'TermsModel', title: string, updatedAt: any, text: string, id: string };

export type IsAuthorizedByMollieFragFragment = { __typename?: 'MollieAuthorization', isAuthorized?: boolean };

export type BenefitFormFragment = { __typename?: 'BenefitModel', id: string, title: string, description?: string };

export type BaseBenefitFragment = { __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, active?: boolean };

export type BaseConditionFragment = { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, condition?: string };

export type BaseDiscountCodeFragment = { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description?: string, discount: number };

export type BaseExtraFragment = { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, type: string, when: string, value: number, active: boolean, subscriptionId: string };

export type SubscriptionFormFragment = { __typename?: 'SubscriptionModel', title: string, description?: string, vatPercentage: number, billingDateType: number, billingDayOfWeek: string, billingPartOfMonth: string, billingDayOfMonth: number };

export type BaseSubscriptionFragment = { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string };

export type TierFormFragment = { __typename?: 'TierModel', title: string, description?: string, price: number, frequency: string };

export type BaseTierFragment = { __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, price: number, frequency: string, extends?: string, subscriptionId: string };

export type BaseIdentityFragment = { __typename?: 'Identity', domain: string, user: { __typename?: 'User', id: string, name: string, email: string, latestTerms: string, mfaEnabled: boolean, mfaVerified: boolean } };

export type UserRegistrationFormFragment = { __typename?: 'UserRegistrationItem', firstName: string, lastName: string, email: string, phone: string, address: string, extra: string, city: string, postalCode: string };

export type RegisterMerchantFormFragment = { __typename?: 'MerchantRegistrationItem', name: string, coc: string, vat?: string, address: string, extra: string, city: string, postalCode: string };

export type PlansFragment = { __typename?: 'TjeckoSub', id: string, title: string, vatPercentage: number, intervals?: Array<{ __typename?: 'SubscriptionIntervalModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, subscriptionId: string, interval: string, discountAmount?: number, discountType: string }>, tiers?: Array<{ __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, price: number, frequency: string, extends?: string, subscriptionId: string, TierHasBenefits: Array<{ __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, subscriptionId: string, active?: boolean }>, TierHasClauses: Array<{ __typename?: 'ClauseFixedModel', id: string, subscription: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, clauseType: string, amount: number, amountType: string, period: string, interval: string, active: boolean, disabledTill?: any, enabledTill?: any, order: number }>, subscription?: { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, vatPercentage: number, billingDateType: number, billingDayOfWeek: string, billingPartOfMonth: string, billingDayOfMonth: number } }> };

export type BaseUserFragment = { __typename?: 'UserModel', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any, mfaEnabled: boolean, mfaVerified: boolean, paymentMethod?: string };

export type GetRegisterCompanyFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetRegisterCompanyFormQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyModel', name: string, invoiceEmail: string, cocInfo?: { __typename?: 'CocInfoModel', id: string, vat?: string }, addresses?: Array<{ __typename?: 'AddressModel', id: string, address: string, postalCode: string, city: string }> } };

export type RegisterCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type RegisterCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyModel', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type GetCompanyFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCompanyFormQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyModel', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyModel', id: string, name: string, invoiceEmail: string, invoiceRecipient: string, createdAt: any, updatedAt: any } };

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = { __typename?: 'Query', companies?: Array<{ __typename?: 'CompanyModel', id: string, name: string, invoiceEmail: string, invoiceRecipient: string, createdAt: any, updatedAt: any }> };

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyModel', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'CompanyModel', name: string, invoiceEmail: string, invoiceRecipient: string } };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: boolean };

export type AddCocToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddCocToCompanyMutation = { __typename?: 'Mutation', addCocToCompany?: boolean };

export type AddAddressToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddAddressToCompanyMutation = { __typename?: 'Mutation', addAddressToCompany?: boolean };

export type AddThemeToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddThemeToCompanyMutation = { __typename?: 'Mutation', addThemeToCompany?: boolean };

export type AddContactToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddContactToCompanyMutation = { __typename?: 'Mutation', addContactToCompany?: boolean };

export type RemoveCocFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveCocFromCompanyMutation = { __typename?: 'Mutation', removeCocFromCompany?: boolean };

export type RemoveAddressFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveAddressFromCompanyMutation = { __typename?: 'Mutation', removeAddressFromCompany?: boolean };

export type RemoveThemeFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveThemeFromCompanyMutation = { __typename?: 'Mutation', removeThemeFromCompany?: boolean };

export type RemoveContactFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveContactFromCompanyMutation = { __typename?: 'Mutation', removeContactFromCompany?: boolean };

export type GetMerchantThemeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMerchantThemeQuery = { __typename?: 'Query', merchantTheme?: { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo?: string, primaryColor?: string, secondaryColor?: string, isFormal?: boolean } };

export type GetMerchantThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantThemesQuery = { __typename?: 'Query', merchantThemes?: Array<{ __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo?: string, primaryColor?: string, secondaryColor?: string, isFormal?: boolean }> };

export type CreateMerchantThemeMutationVariables = Exact<{
  input: CreateMerchantThemeInput;
}>;


export type CreateMerchantThemeMutation = { __typename?: 'Mutation', createMerchantTheme: { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo?: string, primaryColor?: string, secondaryColor?: string, isFormal?: boolean } };

export type UpdateMerchantThemeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateMerchantThemeInput;
}>;


export type UpdateMerchantThemeMutation = { __typename?: 'Mutation', updateMerchantTheme: { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, companyLogo?: string, primaryColor?: string, secondaryColor?: string, isFormal?: boolean } };

export type DeleteMerchantThemeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteMerchantThemeMutation = { __typename?: 'Mutation', deleteMerchantTheme: boolean };

export type GetAddressQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAddressQuery = { __typename?: 'Query', address?: { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string } };

export type GetAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAddressesQuery = { __typename?: 'Query', addresses?: Array<{ __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string }> };

export type CreateAddressMutationVariables = Exact<{
  input: CreateAddressInput;
}>;


export type CreateAddressMutation = { __typename?: 'Mutation', createAddress: { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string } };

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress: { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string } };

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAddressMutation = { __typename?: 'Mutation', deleteAddress: boolean };

export type GetContactQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetContactQuery = { __typename?: 'Query', contact?: { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string } };

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = { __typename?: 'Query', contacts?: Array<{ __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string }> };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string } };

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact: { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string } };

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact: boolean };

export type LatestTermsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type LatestTermsQuery = { __typename?: 'Query', latestTerms?: { __typename?: 'TermsModel', title: string, updatedAt: any, text: string, id: string } };

export type IsAuthorizedByMollieQueryVariables = Exact<{ [key: string]: never; }>;


export type IsAuthorizedByMollieQuery = { __typename?: 'Query', mollieToken?: { __typename?: 'MollieAuthorization', isAuthorized?: boolean } };

export type GetBenefitFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetBenefitFormQuery = { __typename?: 'Query', benefit?: { __typename?: 'BenefitModel', id: string, title: string, description?: string } };

export type GetBenefitQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetBenefitQuery = { __typename?: 'Query', benefit?: { __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, active?: boolean } };

export type GetBenefitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBenefitsQuery = { __typename?: 'Query', benefits?: Array<{ __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, active?: boolean }> };

export type GetBenefitsByTierIdQueryVariables = Exact<{
  tierId: Scalars['ID']['input'];
}>;


export type GetBenefitsByTierIdQuery = { __typename?: 'Query', benefitsByTierId?: Array<{ __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, active?: boolean }> };

export type GetBenefitsBySubscriptionIdQueryVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
}>;


export type GetBenefitsBySubscriptionIdQuery = { __typename?: 'Query', benefitsBySubscriptionId?: Array<{ __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, active?: boolean }> };

export type GetBenefitsBySubscriptionIdAndTierIdQueryVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
}>;


export type GetBenefitsBySubscriptionIdAndTierIdQuery = { __typename?: 'Query', benefitsBySubscriptionIdAndTierId?: Array<{ __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, active?: boolean }> };

export type CreateBenefitMutationVariables = Exact<{
  input: CreateBenefitInput;
}>;


export type CreateBenefitMutation = { __typename?: 'Mutation', createBenefit: { __typename?: 'BenefitModel', id: string, title: string, description?: string } };

export type AddBenefitToTierMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
}>;


export type AddBenefitToTierMutation = { __typename?: 'Mutation', addBenefitToTier?: boolean };

export type RemoveBenefitForTierMutationVariables = Exact<{
  benefitId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
}>;


export type RemoveBenefitForTierMutation = { __typename?: 'Mutation', removeBenefitForTier?: boolean };

export type CreateBenefitForTierMutationVariables = Exact<{
  input: CreateBenefitForTierInput;
}>;


export type CreateBenefitForTierMutation = { __typename?: 'Mutation', createBenefitForTier: { __typename?: 'BenefitModel', id: string, title: string, description?: string } };

export type UpdateBenefitMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateBenefitInput;
}>;


export type UpdateBenefitMutation = { __typename?: 'Mutation', updateBenefit: { __typename?: 'BenefitModel', id: string, title: string, description?: string } };

export type DeleteBenefitMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteBenefitMutation = { __typename?: 'Mutation', deleteBenefit: boolean };

export type GetConditionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetConditionQuery = { __typename?: 'Query', condition?: { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, condition?: string } };

export type GetConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConditionsQuery = { __typename?: 'Query', conditions?: Array<{ __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, condition?: string }> };

export type CreateConditionMutationVariables = Exact<{
  input: CreateConditionInput;
}>;


export type CreateConditionMutation = { __typename?: 'Mutation', createCondition: { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, condition?: string } };

export type UpdateConditionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateConditionInput;
}>;


export type UpdateConditionMutation = { __typename?: 'Mutation', updateCondition: { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, condition?: string } };

export type DeleteConditionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteConditionMutation = { __typename?: 'Mutation', deleteCondition: boolean };

export type GetDiscountCodeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDiscountCodeQuery = { __typename?: 'Query', discountCode?: { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description?: string, discount: number } };

export type GetDiscountCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiscountCodesQuery = { __typename?: 'Query', discountCodes?: Array<{ __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description?: string, discount: number }> };

export type CreateDiscountCodeMutationVariables = Exact<{
  input: CreateDiscountCodeInput;
}>;


export type CreateDiscountCodeMutation = { __typename?: 'Mutation', createDiscountCode: { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description?: string, discount: number } };

export type UpdateDiscountCodeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateDiscountCodeInput;
}>;


export type UpdateDiscountCodeMutation = { __typename?: 'Mutation', updateDiscountCode: { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, useBefore?: any, title: string, description?: string, discount: number } };

export type DeleteDiscountCodeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDiscountCodeMutation = { __typename?: 'Mutation', deleteDiscountCode: boolean };

export type GetExtraQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetExtraQuery = { __typename?: 'Query', extra?: { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, type: string, when: string, value: number, active: boolean, subscriptionId: string } };

export type GetExtrasBySubscriptionIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetExtrasBySubscriptionIdQuery = { __typename?: 'Query', extrasBySubscriptionId?: Array<{ __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, type: string, when: string, value: number, active: boolean, subscriptionId: string }> };

export type GetExtrasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExtrasQuery = { __typename?: 'Query', extras?: Array<{ __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, type: string, when: string, value: number, active: boolean, subscriptionId: string }> };

export type CreateExtraMutationVariables = Exact<{
  input: CreateExtraInput;
}>;


export type CreateExtraMutation = { __typename?: 'Mutation', createExtra: { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, type: string, when: string, value: number, active: boolean, subscriptionId: string } };

export type AddExtraToTierMutationVariables = Exact<{
  extraId: Scalars['ID']['input'];
  tierId: Scalars['ID']['input'];
}>;


export type AddExtraToTierMutation = { __typename?: 'Mutation', addExtraToTier: boolean };

export type UpdateExtraMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateExtraInput;
}>;


export type UpdateExtraMutation = { __typename?: 'Mutation', updateExtra: { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, type: string, when: string, value: number, active: boolean, subscriptionId: string } };

export type DeleteExtraMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteExtraMutation = { __typename?: 'Mutation', deleteExtra: boolean };

export type GetSubscriptionFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSubscriptionFormQuery = { __typename?: 'Query', subscription?: { __typename?: 'SubscriptionModel', title: string, description?: string, vatPercentage: number, billingDateType: number, billingDayOfWeek: string, billingPartOfMonth: string, billingDayOfMonth: number } };

export type GetSubscriptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSubscriptionQuery = { __typename?: 'Query', subscription?: { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string } };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'Query', subscriptions?: Array<{ __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string }> };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'SubscriptionModel', title: string, description?: string, vatPercentage: number, billingDateType: number, billingDayOfWeek: string, billingPartOfMonth: string, billingDayOfMonth: number } };

export type UpdateSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'SubscriptionModel', title: string, description?: string, vatPercentage: number, billingDateType: number, billingDayOfWeek: string, billingPartOfMonth: string, billingDayOfMonth: number } };

export type DeleteSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteSubscriptionMutation = { __typename?: 'Mutation', deleteSubscription: boolean };

export type GetTierFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTierFormQuery = { __typename?: 'Query', tier?: { __typename?: 'TierModel', title: string, description?: string, price: number, frequency: string } };

export type GetTierQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTierQuery = { __typename?: 'Query', tier?: { __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, price: number, frequency: string, extends?: string, subscriptionId: string } };

export type GetTiersBySubscriptionIdQueryVariables = Exact<{
  subscriptionId: Scalars['ID']['input'];
}>;


export type GetTiersBySubscriptionIdQuery = { __typename?: 'Query', tiersBySubscriptionId?: Array<{ __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, price: number, frequency: string, extends?: string, subscriptionId: string }> };

export type GetTiersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTiersQuery = { __typename?: 'Query', tiers?: Array<{ __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, price: number, frequency: string, extends?: string, subscriptionId: string }> };

export type CreateTierMutationVariables = Exact<{
  input: CreateTierInput;
}>;


export type CreateTierMutation = { __typename?: 'Mutation', createTier: { __typename?: 'TierModel', title: string, description?: string, price: number, frequency: string } };

export type UpdateTierMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateTierInput;
}>;


export type UpdateTierMutation = { __typename?: 'Mutation', updateTier: { __typename?: 'TierModel', title: string, description?: string, price: number, frequency: string } };

export type DeleteTierMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTierMutation = { __typename?: 'Mutation', deleteTier: boolean };

export type AddTierToUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  interval: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
  discount?: InputMaybe<Scalars['ID']['input']>;
}>;


export type AddTierToUserMutation = { __typename?: 'Mutation', addTierToUser: string };

export type GetShoppingCartQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShoppingCartQuery = { __typename?: 'Query', getShoppingCart?: Array<{ __typename?: 'ShoppingCart', UserID: string, TierID: string, IntervalID: string }> };

export type AddToShoppingCartMutationVariables = Exact<{
  UserID: Scalars['ID']['input'];
  TierID: Scalars['ID']['input'];
  IntervalID: Scalars['ID']['input'];
}>;


export type AddToShoppingCartMutation = { __typename?: 'Mutation', addTierToShoppingCart?: boolean };

export type RemoveTierFromShoppingCartMutationVariables = Exact<{
  UserID: Scalars['ID']['input'];
  TierID: Scalars['ID']['input'];
  IntervalID: Scalars['ID']['input'];
}>;


export type RemoveTierFromShoppingCartMutation = { __typename?: 'Mutation', removeTierFromShoppingCart?: boolean };

export type GetIdentitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIdentitiesQuery = { __typename?: 'Query', identities: { __typename?: 'Identity', domain: string, user: { __typename?: 'User', id: string, name: string, email: string, latestTerms: string, mfaEnabled: boolean, mfaVerified: boolean } } };

export type SetActiveMerchantMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SetActiveMerchantMutation = { __typename?: 'Mutation', setActiveMerchant?: boolean };

export type GetCurrentMerchantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentMerchantQuery = { __typename?: 'Query', getCurrentMerchant?: { __typename?: 'CompanyModel', id: string, name: string, invoiceEmail: string, invoiceRecipient: string, cocInfo?: { __typename?: 'CocInfoModel', id: string, coc?: string, vat?: string }, addresses?: Array<{ __typename?: 'AddressModel', id: string, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string }> } };

export type RegisterMerchantContactMutationVariables = Exact<{
  input: UserRegistrationInput;
}>;


export type RegisterMerchantContactMutation = { __typename?: 'Mutation', registerMerchantContact?: { __typename?: 'UserRegistrationItem', firstName: string, lastName: string, email: string, phone: string, address: string, extra: string, city: string, postalCode: string } };

export type RegisterMerchantMutationVariables = Exact<{
  input: MerchantRegistrationInput;
}>;


export type RegisterMerchantMutation = { __typename?: 'Mutation', registerMerchant: { __typename?: 'MerchantRegistrationItem', name: string, coc: string, vat?: string, address: string, extra: string, city: string, postalCode: string } };

export type GetRegisterMerchantFormQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetRegisterMerchantFormQuery = { __typename?: 'Query', getMerchantRegistrationItem: { __typename?: 'MerchantRegistrationItem', name: string, coc: string, vat?: string, address: string, extra: string, city: string, postalCode: string } };

export type GetMerchantProfileProgressQueryVariables = Exact<{
  companyId: Scalars['ID']['input'];
}>;


export type GetMerchantProfileProgressQuery = { __typename?: 'Query', merchantProfile?: { __typename?: 'MerchantProfileProgress', complete: boolean, onboardingProgress: { __typename?: 'MerchantOnboardingProgress', contactIsComplete: boolean, addressIsComplete: boolean, subscriptionIsComplete: boolean, memberIsComplete: boolean } } };

export type GetTjeckoSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTjeckoSubscriptionQuery = { __typename?: 'Query', tjeckoSubscription: { __typename?: 'TjeckoSub', id: string, title: string, vatPercentage: number, intervals?: Array<{ __typename?: 'SubscriptionIntervalModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, subscriptionId: string, interval: string, discountAmount?: number, discountType: string }>, tiers?: Array<{ __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, price: number, frequency: string, extends?: string, subscriptionId: string, TierHasBenefits: Array<{ __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, subscriptionId: string, active?: boolean }>, TierHasClauses: Array<{ __typename?: 'ClauseFixedModel', id: string, subscription: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, clauseType: string, amount: number, amountType: string, period: string, interval: string, active: boolean, disabledTill?: any, enabledTill?: any, order: number }>, subscription?: { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, vatPercentage: number, billingDateType: number, billingDayOfWeek: string, billingPartOfMonth: string, billingDayOfMonth: number } }> } };

export type GetTjeckoTierQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTjeckoTierQuery = { __typename?: 'Query', getTjeckoTier: { __typename?: 'TjeckoTier', id: string, title: string, vatPercentage: number, interval: { __typename?: 'SubscriptionIntervalModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, subscriptionId: string, interval: string, discountAmount?: number, discountType: string }, tier: { __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, title: string, description?: string, price: number, frequency: string, extends?: string, subscriptionId: string } } };

export type GetMerchantTjeckoSubStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantTjeckoSubStatusQuery = { __typename?: 'Query', getMerchantTjeckoSubStatus: { __typename?: 'MerchantTjeckoSubStatus', status: string, paidLatest: boolean, initialPaymentComplete: boolean } };

export type MerchantProfileQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type MerchantProfileQuery = { __typename?: 'Query', merchantProfile?: { __typename?: 'MerchantProfileProgress', companyId: string, complete: boolean, onboardingProgress: { __typename?: 'MerchantOnboardingProgress', addressIsComplete: boolean, subscriptionIsComplete: boolean, contactIsComplete: boolean, profileIsComplete: boolean, companyIsComplete: boolean, planIsComplete: boolean, tosIsComplete: boolean, paymentIsComplete: boolean, paymentMethodIsComplete: boolean, memberIsComplete: boolean } } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'UserModel', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any, mfaEnabled: boolean, mfaVerified: boolean, paymentMethod?: string, address?: Array<{ __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string }>, companies?: Array<{ __typename?: 'CompanyModel', name: string }>, subscriptionTiers?: Array<{ __typename?: 'TierModel', title: string, description?: string }> } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'UserModel', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any, mfaEnabled: boolean, mfaVerified: boolean, paymentMethod?: string, address?: Array<{ __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string }>, companies?: Array<{ __typename?: 'CompanyModel', name: string }>, subscriptionTiers?: Array<{ __typename?: 'TierModel', title: string, description?: string }> }> };

export type GetUserFormDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserFormDataQuery = { __typename?: 'Query', user?: { __typename?: 'UserModel', firstName: string, lastName: string } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserModel', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any, mfaEnabled: boolean, mfaVerified: boolean, paymentMethod?: string } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserModel', id: string, firstName: string, lastName: string, email: string, createdAt: any, updatedAt: any, mfaEnabled: boolean, mfaVerified: boolean, paymentMethod?: string } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type AddAddressToUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddAddressToUserMutation = { __typename?: 'Mutation', addAddressToUser?: boolean };

export type AddCompanyToUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddCompanyToUserMutation = { __typename?: 'Mutation', addCompanyToUser?: boolean };

export type AcceptTermsForUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AcceptTermsForUserMutation = { __typename?: 'Mutation', acceptTermsForUser?: boolean };

export type RemoveAddressFromUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveAddressFromUserMutation = { __typename?: 'Mutation', removeAddressFromUser?: boolean };

export type RemoveCompanyFromUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveCompanyFromUserMutation = { __typename?: 'Mutation', removeCompanyFromUser?: boolean };

export type UnacceptTermsForUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type UnacceptTermsForUserMutation = { __typename?: 'Mutation', unacceptTermsForUser?: boolean };

export const BaseCompanyFragmentDoc = gql`
    fragment BaseCompany on CompanyModel {
  id
  name
  invoiceEmail
  invoiceRecipient
  createdAt
  updatedAt
}
    `;
export const CompanyFormFragmentDoc = gql`
    fragment CompanyForm on CompanyModel {
  name
  invoiceEmail
  invoiceRecipient
}
    `;
export const RegisterCompanyFormFragmentDoc = gql`
    fragment RegisterCompanyForm on CompanyModel {
  name
  invoiceEmail
  cocInfo {
    id
    vat
  }
  addresses {
    id
    address
    postalCode
    city
  }
}
    `;
export const BaseMerchantThemeFragmentDoc = gql`
    fragment BaseMerchantTheme on MerchantThemeModel {
  id
  createdAt
  updatedAt
  deletedAt
  companyLogo
  primaryColor
  secondaryColor
  isFormal
}
    `;
export const BaseAddressFragmentDoc = gql`
    fragment BaseAddress on AddressModel {
  id
  createdAt
  updatedAt
  deletedAt
  addressType
  company
  address
  extra
  city
  province
  postalCode
}
    `;
export const BaseContactFragmentDoc = gql`
    fragment BaseContact on ContactModel {
  id
  createdAt
  updatedAt
  deletedAt
  firstName
  middleName
  lastName
  position
  email
  phone
}
    `;
export const MinimumTosFragmentDoc = gql`
    fragment MinimumTos on TermsModel {
  title
  updatedAt
  text
  id
}
    `;
export const IsAuthorizedByMollieFragFragmentDoc = gql`
    fragment IsAuthorizedByMollieFrag on MollieAuthorization {
  isAuthorized
}
    `;
export const BaseBenefitFragmentDoc = gql`
    fragment BaseBenefit on BenefitModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  active
}
    `;
export const BenefitFormFragmentDoc = gql`
    fragment BenefitForm on BenefitModel {
  id
  title
  description
}
    `;
export const BaseConditionFragmentDoc = gql`
    fragment BaseCondition on ConditionModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  condition
}
    `;
export const BaseDiscountCodeFragmentDoc = gql`
    fragment BaseDiscountCode on DiscountCodeModel {
  id
  createdAt
  updatedAt
  deletedAt
  useBefore
  title
  description
  discount
}
    `;
export const BaseExtraFragmentDoc = gql`
    fragment BaseExtra on ExtraModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  type
  when
  value
  active
  subscriptionId
}
    `;
export const BaseSubscriptionFragmentDoc = gql`
    fragment BaseSubscription on SubscriptionModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
}
    `;
export const SubscriptionFormFragmentDoc = gql`
    fragment SubscriptionForm on SubscriptionModel {
  title
  description
  vatPercentage
  billingDateType
  billingDayOfWeek
  billingPartOfMonth
  billingDayOfMonth
}
    `;
export const BaseTierFragmentDoc = gql`
    fragment BaseTier on TierModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  price
  frequency
  extends
  subscriptionId
}
    `;
export const TierFormFragmentDoc = gql`
    fragment TierForm on TierModel {
  title
  description
  price
  frequency
}
    `;
export const BaseIdentityFragmentDoc = gql`
    fragment BaseIdentity on Identity {
  domain
  user {
    id
    name
    email
    latestTerms
    mfaEnabled
    mfaVerified
  }
}
    `;
export const RegisterMerchantFormFragmentDoc = gql`
    fragment RegisterMerchantForm on MerchantRegistrationItem {
  name
  coc
  vat
  address
  extra
  city
  postalCode
}
    `;
export const UserRegistrationFormFragmentDoc = gql`
    fragment UserRegistrationForm on UserRegistrationItem {
  firstName
  lastName
  email
  phone
  address
  extra
  city
  postalCode
}
    `;
export const PlansFragmentDoc = gql`
    fragment Plans on TjeckoSub {
  id
  title
  vatPercentage
  intervals {
    id
    createdAt
    updatedAt
    deletedAt
    subscriptionId
    interval
    discountAmount
    discountType
  }
  tiers {
    id
    createdAt
    updatedAt
    deletedAt
    title
    description
    price
    frequency
    extends
    subscriptionId
    TierHasBenefits {
      id
      createdAt
      updatedAt
      deletedAt
      title
      description
      subscriptionId
      active
    }
    TierHasClauses {
      id
      subscription
      createdAt
      updatedAt
      deletedAt
      title
      description
      clauseType
      amount
      amountType
      period
      interval
      active
      disabledTill
      enabledTill
      order
    }
    subscription {
      id
      createdAt
      updatedAt
      deletedAt
      title
      description
      vatPercentage
      billingDateType
      billingDayOfWeek
      billingPartOfMonth
      billingDayOfMonth
    }
  }
}
    `;
export const BaseUserFragmentDoc = gql`
    fragment BaseUser on UserModel {
  id
  firstName
  lastName
  email
  createdAt
  updatedAt
  mfaEnabled
  mfaVerified
  paymentMethod
}
    `;
export const GetRegisterCompanyFormDocument = gql`
    query GetRegisterCompanyForm($id: ID!) {
  company(id: $id) {
    ...RegisterCompanyForm
  }
}
    ${RegisterCompanyFormFragmentDoc}`;
export const RegisterCompanyDocument = gql`
    mutation RegisterCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const GetCompanyFormDocument = gql`
    query GetCompanyForm($id: ID!) {
  company(id: $id) {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const GetCompanyDocument = gql`
    query GetCompany($id: ID!) {
  company(id: $id) {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  companies {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: ID!, $input: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $input) {
    ...CompanyForm
  }
}
    ${CompanyFormFragmentDoc}`;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: ID!) {
  deleteCompany(id: $id)
}
    `;
export const AddCocToCompanyDocument = gql`
    mutation AddCocToCompany($relation: ID!, $record: ID!) {
  addCocToCompany(relation: $relation, record: $record)
}
    `;
export const AddAddressToCompanyDocument = gql`
    mutation AddAddressToCompany($relation: ID!, $record: ID!) {
  addAddressToCompany(relation: $relation, record: $record)
}
    `;
export const AddThemeToCompanyDocument = gql`
    mutation AddThemeToCompany($relation: ID!, $record: ID!) {
  addThemeToCompany(relation: $relation, record: $record)
}
    `;
export const AddContactToCompanyDocument = gql`
    mutation AddContactToCompany($relation: ID!, $record: ID!) {
  addContactToCompany(relation: $relation, record: $record)
}
    `;
export const RemoveCocFromCompanyDocument = gql`
    mutation RemoveCocFromCompany($relation: ID!, $record: ID!) {
  removeCocFromCompany(relation: $relation, record: $record)
}
    `;
export const RemoveAddressFromCompanyDocument = gql`
    mutation RemoveAddressFromCompany($relation: ID!, $record: ID!) {
  removeAddressFromCompany(relation: $relation, record: $record)
}
    `;
export const RemoveThemeFromCompanyDocument = gql`
    mutation RemoveThemeFromCompany($relation: ID!, $record: ID!) {
  removeThemeFromCompany(relation: $relation, record: $record)
}
    `;
export const RemoveContactFromCompanyDocument = gql`
    mutation RemoveContactFromCompany($relation: ID!, $record: ID!) {
  removeContactFromCompany(relation: $relation, record: $record)
}
    `;
export const GetMerchantThemeDocument = gql`
    query GetMerchantTheme($id: ID!) {
  merchantTheme(id: $id) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const GetMerchantThemesDocument = gql`
    query GetMerchantThemes {
  merchantThemes {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const CreateMerchantThemeDocument = gql`
    mutation CreateMerchantTheme($input: CreateMerchantThemeInput!) {
  createMerchantTheme(input: $input) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const UpdateMerchantThemeDocument = gql`
    mutation UpdateMerchantTheme($id: ID!, $input: UpdateMerchantThemeInput!) {
  updateMerchantTheme(id: $id, input: $input) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const DeleteMerchantThemeDocument = gql`
    mutation DeleteMerchantTheme($id: ID!) {
  deleteMerchantTheme(id: $id)
}
    `;
export const GetAddressDocument = gql`
    query GetAddress($id: ID!) {
  address(id: $id) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const GetAddressesDocument = gql`
    query GetAddresses {
  addresses {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const CreateAddressDocument = gql`
    mutation CreateAddress($input: CreateAddressInput!) {
  createAddress(input: $input) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: ID!, $input: UpdateAddressInput!) {
  updateAddress(id: $id, input: $input) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const DeleteAddressDocument = gql`
    mutation DeleteAddress($id: ID!) {
  deleteAddress(id: $id)
}
    `;
export const GetContactDocument = gql`
    query GetContact($id: ID!) {
  contact(id: $id) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const GetContactsDocument = gql`
    query GetContacts {
  contacts {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const UpdateContactDocument = gql`
    mutation UpdateContact($id: ID!, $input: UpdateContactInput!) {
  updateContact(id: $id, input: $input) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: ID!) {
  deleteContact(id: $id)
}
    `;
export const LatestTermsDocument = gql`
    query LatestTerms($companyId: ID) {
  latestTerms(companyId: $companyId) {
    ...MinimumTos
  }
}
    ${MinimumTosFragmentDoc}`;
export const IsAuthorizedByMollieDocument = gql`
    query IsAuthorizedByMollie {
  mollieToken {
    ...IsAuthorizedByMollieFrag
  }
}
    ${IsAuthorizedByMollieFragFragmentDoc}`;
export const GetBenefitFormDocument = gql`
    query GetBenefitForm($id: ID!) {
  benefit(id: $id) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const GetBenefitDocument = gql`
    query GetBenefit($id: ID!) {
  benefit(id: $id) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const GetBenefitsDocument = gql`
    query GetBenefits {
  benefits {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const GetBenefitsByTierIdDocument = gql`
    query GetBenefitsByTierId($tierId: ID!) {
  benefitsByTierId(tierId: $tierId) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const GetBenefitsBySubscriptionIdDocument = gql`
    query GetBenefitsBySubscriptionId($subscriptionId: ID!) {
  benefitsBySubscriptionId(subscriptionId: $subscriptionId) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const GetBenefitsBySubscriptionIdAndTierIdDocument = gql`
    query GetBenefitsBySubscriptionIdAndTierId($subscriptionId: ID!, $tierId: ID!) {
  benefitsBySubscriptionIdAndTierId(
    subscriptionId: $subscriptionId
    tierId: $tierId
  ) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const CreateBenefitDocument = gql`
    mutation CreateBenefit($input: CreateBenefitInput!) {
  createBenefit(input: $input) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const AddBenefitToTierDocument = gql`
    mutation AddBenefitToTier($benefitId: ID!, $tierId: ID!) {
  addBenefitToTier(benefitId: $benefitId, tierId: $tierId)
}
    `;
export const RemoveBenefitForTierDocument = gql`
    mutation RemoveBenefitForTier($benefitId: ID!, $tierId: ID!) {
  removeBenefitForTier(benefitId: $benefitId, tierId: $tierId)
}
    `;
export const CreateBenefitForTierDocument = gql`
    mutation CreateBenefitForTier($input: CreateBenefitForTierInput!) {
  createBenefitForTier(input: $input) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const UpdateBenefitDocument = gql`
    mutation UpdateBenefit($id: ID!, $input: UpdateBenefitInput!) {
  updateBenefit(id: $id, input: $input) {
    ...BenefitForm
  }
}
    ${BenefitFormFragmentDoc}`;
export const DeleteBenefitDocument = gql`
    mutation DeleteBenefit($id: ID!) {
  deleteBenefit(id: $id)
}
    `;
export const GetConditionDocument = gql`
    query GetCondition($id: ID!) {
  condition(id: $id) {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const GetConditionsDocument = gql`
    query GetConditions {
  conditions {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const CreateConditionDocument = gql`
    mutation CreateCondition($input: CreateConditionInput!) {
  createCondition(input: $input) {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const UpdateConditionDocument = gql`
    mutation UpdateCondition($id: ID!, $input: UpdateConditionInput!) {
  updateCondition(id: $id, input: $input) {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const DeleteConditionDocument = gql`
    mutation DeleteCondition($id: ID!) {
  deleteCondition(id: $id)
}
    `;
export const GetDiscountCodeDocument = gql`
    query GetDiscountCode($id: ID!) {
  discountCode(id: $id) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const GetDiscountCodesDocument = gql`
    query GetDiscountCodes {
  discountCodes {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const CreateDiscountCodeDocument = gql`
    mutation CreateDiscountCode($input: CreateDiscountCodeInput!) {
  createDiscountCode(input: $input) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const UpdateDiscountCodeDocument = gql`
    mutation UpdateDiscountCode($id: ID!, $input: UpdateDiscountCodeInput!) {
  updateDiscountCode(id: $id, input: $input) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const DeleteDiscountCodeDocument = gql`
    mutation DeleteDiscountCode($id: ID!) {
  deleteDiscountCode(id: $id)
}
    `;
export const GetExtraDocument = gql`
    query GetExtra($id: ID!) {
  extra(id: $id) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const GetExtrasBySubscriptionIdDocument = gql`
    query GetExtrasBySubscriptionId($id: ID!) {
  extrasBySubscriptionId(subscriptionId: $id) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const GetExtrasDocument = gql`
    query GetExtras {
  extras {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const CreateExtraDocument = gql`
    mutation CreateExtra($input: CreateExtraInput!) {
  createExtra(input: $input) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const AddExtraToTierDocument = gql`
    mutation AddExtraToTier($extraId: ID!, $tierId: ID!) {
  addExtraToTier(extraId: $extraId, tierId: $tierId)
}
    `;
export const UpdateExtraDocument = gql`
    mutation UpdateExtra($id: ID!, $input: UpdateExtraInput!) {
  updateExtra(id: $id, input: $input) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const DeleteExtraDocument = gql`
    mutation DeleteExtra($id: ID!) {
  deleteExtra(id: $id)
}
    `;
export const GetSubscriptionFormDocument = gql`
    query GetSubscriptionForm($id: ID!) {
  subscription(id: $id) {
    ...SubscriptionForm
  }
}
    ${SubscriptionFormFragmentDoc}`;
export const GetSubscriptionDocument = gql`
    query GetSubscription($id: ID!) {
  subscription(id: $id) {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  subscriptions {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    ...SubscriptionForm
  }
}
    ${SubscriptionFormFragmentDoc}`;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($id: ID!, $input: UpdateSubscriptionInput!) {
  updateSubscription(id: $id, input: $input) {
    ...SubscriptionForm
  }
}
    ${SubscriptionFormFragmentDoc}`;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($id: ID!) {
  deleteSubscription(id: $id)
}
    `;
export const GetTierFormDocument = gql`
    query GetTierForm($id: ID!) {
  tier(id: $id) {
    ...TierForm
  }
}
    ${TierFormFragmentDoc}`;
export const GetTierDocument = gql`
    query GetTier($id: ID!) {
  tier(id: $id) {
    ...BaseTier
  }
}
    ${BaseTierFragmentDoc}`;
export const GetTiersBySubscriptionIdDocument = gql`
    query GetTiersBySubscriptionId($subscriptionId: ID!) {
  tiersBySubscriptionId(subscriptionId: $subscriptionId) {
    ...BaseTier
  }
}
    ${BaseTierFragmentDoc}`;
export const GetTiersDocument = gql`
    query GetTiers {
  tiers {
    ...BaseTier
  }
}
    ${BaseTierFragmentDoc}`;
export const CreateTierDocument = gql`
    mutation CreateTier($input: CreateTierInput!) {
  createTier(input: $input) {
    ...TierForm
  }
}
    ${TierFormFragmentDoc}`;
export const UpdateTierDocument = gql`
    mutation UpdateTier($id: ID!, $input: UpdateTierInput!) {
  updateTier(id: $id, input: $input) {
    ...TierForm
  }
}
    ${TierFormFragmentDoc}`;
export const DeleteTierDocument = gql`
    mutation DeleteTier($id: ID!) {
  deleteTier(id: $id)
}
    `;
export const AddTierToUserDocument = gql`
    mutation AddTierToUser($relation: ID!, $interval: ID!, $record: ID!, $discount: ID) {
  addTierToUser(
    relation: $relation
    interval: $interval
    record: $record
    discount: $discount
  )
}
    `;
export const GetShoppingCartDocument = gql`
    query GetShoppingCart {
  getShoppingCart {
    UserID
    TierID
    IntervalID
  }
}
    `;
export const AddToShoppingCartDocument = gql`
    mutation AddToShoppingCart($UserID: ID!, $TierID: ID!, $IntervalID: ID!) {
  addTierToShoppingCart(
    input: {UserID: $UserID, TierID: $TierID, IntervalID: $IntervalID}
  )
}
    `;
export const RemoveTierFromShoppingCartDocument = gql`
    mutation RemoveTierFromShoppingCart($UserID: ID!, $TierID: ID!, $IntervalID: ID!) {
  removeTierFromShoppingCart(
    input: {UserID: $UserID, TierID: $TierID, IntervalID: $IntervalID}
  )
}
    `;
export const GetIdentitiesDocument = gql`
    query GetIdentities {
  identities {
    ...BaseIdentity
  }
}
    ${BaseIdentityFragmentDoc}`;
export const SetActiveMerchantDocument = gql`
    mutation SetActiveMerchant($id: ID!) {
  setActiveMerchant(id: $id)
}
    `;
export const GetCurrentMerchantDocument = gql`
    query GetCurrentMerchant {
  getCurrentMerchant {
    id
    name
    invoiceEmail
    invoiceRecipient
    cocInfo {
      id
      coc
      vat
    }
    addresses {
      id
      addressType
      company
      address
      extra
      city
      province
      postalCode
    }
  }
}
    `;
export const RegisterMerchantContactDocument = gql`
    mutation RegisterMerchantContact($input: UserRegistrationInput!) {
  registerMerchantContact(input: $input) {
    ...UserRegistrationForm
  }
}
    ${UserRegistrationFormFragmentDoc}`;
export const RegisterMerchantDocument = gql`
    mutation RegisterMerchant($input: MerchantRegistrationInput!) {
  registerMerchant(input: $input) {
    ...RegisterMerchantForm
  }
}
    ${RegisterMerchantFormFragmentDoc}`;
export const GetRegisterMerchantFormDocument = gql`
    query GetRegisterMerchantForm($id: ID!) {
  getMerchantRegistrationItem(id: $id) {
    ...RegisterMerchantForm
  }
}
    ${RegisterMerchantFormFragmentDoc}`;
export const GetMerchantProfileProgressDocument = gql`
    query GetMerchantProfileProgress($companyId: ID!) {
  merchantProfile(companyId: $companyId) {
    onboardingProgress {
      contactIsComplete
      addressIsComplete
      subscriptionIsComplete
      memberIsComplete
    }
    complete
  }
}
    `;
export const GetTjeckoSubscriptionDocument = gql`
    query GetTjeckoSubscription {
  tjeckoSubscription {
    ...Plans
  }
}
    ${PlansFragmentDoc}`;
export const GetTjeckoTierDocument = gql`
    query GetTjeckoTier {
  getTjeckoTier {
    id
    title
    vatPercentage
    interval {
      id
      createdAt
      updatedAt
      deletedAt
      subscriptionId
      interval
      discountAmount
      discountType
    }
    tier {
      id
      createdAt
      updatedAt
      deletedAt
      title
      description
      price
      frequency
      extends
      subscriptionId
    }
  }
}
    `;
export const GetMerchantTjeckoSubStatusDocument = gql`
    query GetMerchantTjeckoSubStatus {
  getMerchantTjeckoSubStatus {
    status
    paidLatest
    initialPaymentComplete
  }
}
    `;
export const MerchantProfileDocument = gql`
    query MerchantProfile($companyId: ID) {
  merchantProfile(companyId: $companyId) {
    onboardingProgress {
      addressIsComplete
      subscriptionIsComplete
      contactIsComplete
      profileIsComplete
      companyIsComplete
      planIsComplete
      tosIsComplete
      paymentIsComplete
      paymentMethodIsComplete
      memberIsComplete
    }
    companyId
    complete
  }
}
    `;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  user(id: $id) {
    ...BaseUser
    firstName
    lastName
    address {
      addressType
      city
      ...BaseAddress
    }
    companies {
      name
    }
    subscriptionTiers {
      title
      description
    }
  }
}
    ${BaseUserFragmentDoc}
${BaseAddressFragmentDoc}`;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    ...BaseUser
    firstName
    lastName
    address {
      addressType
      city
      ...BaseAddress
    }
    companies {
      name
    }
    subscriptionTiers {
      title
      description
    }
  }
}
    ${BaseUserFragmentDoc}
${BaseAddressFragmentDoc}`;
export const GetUserFormDataDocument = gql`
    query GetUserFormData($id: ID!) {
  user(id: $id) {
    firstName
    lastName
  }
}
    `;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export const AddAddressToUserDocument = gql`
    mutation AddAddressToUser($relation: ID!, $record: ID!) {
  addAddressToUser(relation: $relation, record: $record)
}
    `;
export const AddCompanyToUserDocument = gql`
    mutation AddCompanyToUser($relation: ID!, $record: ID!) {
  addCompanyToUser(relation: $relation, record: $record)
}
    `;
export const AcceptTermsForUserDocument = gql`
    mutation AcceptTermsForUser($relation: ID!, $record: ID!) {
  acceptTermsForUser(relation: $relation, record: $record)
}
    `;
export const RemoveAddressFromUserDocument = gql`
    mutation RemoveAddressFromUser($relation: ID!, $record: ID!) {
  removeAddressFromUser(relation: $relation, record: $record)
}
    `;
export const RemoveCompanyFromUserDocument = gql`
    mutation RemoveCompanyFromUser($relation: ID!, $record: ID!) {
  removeCompanyFromUser(relation: $relation, record: $record)
}
    `;
export const UnacceptTermsForUserDocument = gql`
    mutation UnacceptTermsForUser($relation: ID!, $record: ID!) {
  unacceptTermsForUser(relation: $relation, record: $record)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
    return {
        GetRegisterCompanyForm(variables: GetRegisterCompanyFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRegisterCompanyFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetRegisterCompanyFormQuery>(GetRegisterCompanyFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRegisterCompanyForm', 'query', variables);
        },
        RegisterCompany(variables: RegisterCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RegisterCompanyMutation>(RegisterCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegisterCompany', 'mutation', variables);
        },
        GetCompanyForm(variables: GetCompanyFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompanyFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCompanyFormQuery>(GetCompanyFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompanyForm', 'query', variables);
        },
        GetCompany(variables: GetCompanyQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompanyQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCompanyQuery>(GetCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompany', 'query', variables);
        },
        GetCompanies(variables?: GetCompaniesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompaniesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCompaniesQuery>(GetCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompanies', 'query', variables);
        },
        CreateCompany(variables: CreateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateCompanyMutation>(CreateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCompany', 'mutation', variables);
        },
        UpdateCompany(variables: UpdateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateCompanyMutation>(UpdateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCompany', 'mutation', variables);
        },
        DeleteCompany(variables: DeleteCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteCompanyMutation>(DeleteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCompany', 'mutation', variables);
        },
        AddCocToCompany(variables: AddCocToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddCocToCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddCocToCompanyMutation>(AddCocToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddCocToCompany', 'mutation', variables);
        },
        AddAddressToCompany(variables: AddAddressToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddAddressToCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddAddressToCompanyMutation>(AddAddressToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddAddressToCompany', 'mutation', variables);
        },
        AddThemeToCompany(variables: AddThemeToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddThemeToCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddThemeToCompanyMutation>(AddThemeToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddThemeToCompany', 'mutation', variables);
        },
        AddContactToCompany(variables: AddContactToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddContactToCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddContactToCompanyMutation>(AddContactToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddContactToCompany', 'mutation', variables);
        },
        RemoveCocFromCompany(variables: RemoveCocFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveCocFromCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveCocFromCompanyMutation>(RemoveCocFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCocFromCompany', 'mutation', variables);
        },
        RemoveAddressFromCompany(variables: RemoveAddressFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveAddressFromCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveAddressFromCompanyMutation>(RemoveAddressFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveAddressFromCompany', 'mutation', variables);
        },
        RemoveThemeFromCompany(variables: RemoveThemeFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveThemeFromCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveThemeFromCompanyMutation>(RemoveThemeFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveThemeFromCompany', 'mutation', variables);
        },
        RemoveContactFromCompany(variables: RemoveContactFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveContactFromCompanyMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveContactFromCompanyMutation>(RemoveContactFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveContactFromCompany', 'mutation', variables);
        },
        GetMerchantTheme(variables: GetMerchantThemeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantThemeQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantThemeQuery>(GetMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantTheme', 'query', variables);
        },
        GetMerchantThemes(variables?: GetMerchantThemesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantThemesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantThemesQuery>(GetMerchantThemesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantThemes', 'query', variables);
        },
        CreateMerchantTheme(variables: CreateMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMerchantThemeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateMerchantThemeMutation>(CreateMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMerchantTheme', 'mutation', variables);
        },
        UpdateMerchantTheme(variables: UpdateMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMerchantThemeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateMerchantThemeMutation>(UpdateMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMerchantTheme', 'mutation', variables);
        },
        DeleteMerchantTheme(variables: DeleteMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteMerchantThemeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteMerchantThemeMutation>(DeleteMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteMerchantTheme', 'mutation', variables);
        },
        GetAddress(variables: GetAddressQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAddressQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetAddressQuery>(GetAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAddress', 'query', variables);
        },
        GetAddresses(variables?: GetAddressesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAddressesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetAddressesQuery>(GetAddressesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAddresses', 'query', variables);
        },
        CreateAddress(variables: CreateAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAddressMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateAddressMutation>(CreateAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAddress', 'mutation', variables);
        },
        UpdateAddress(variables: UpdateAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateAddressMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateAddressMutation>(UpdateAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAddress', 'mutation', variables);
        },
        DeleteAddress(variables: DeleteAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteAddressMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteAddressMutation>(DeleteAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteAddress', 'mutation', variables);
        },
        GetContact(variables: GetContactQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetContactQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetContactQuery>(GetContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContact', 'query', variables);
        },
        GetContacts(variables?: GetContactsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetContactsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetContactsQuery>(GetContactsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContacts', 'query', variables);
        },
        CreateContact(variables: CreateContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateContactMutation>(CreateContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateContact', 'mutation', variables);
        },
        UpdateContact(variables: UpdateContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateContactMutation>(UpdateContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateContact', 'mutation', variables);
        },
        DeleteContact(variables: DeleteContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteContactMutation>(DeleteContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteContact', 'mutation', variables);
        },
        LatestTerms(variables?: LatestTermsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LatestTermsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<LatestTermsQuery>(LatestTermsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LatestTerms', 'query', variables);
        },
        IsAuthorizedByMollie(variables?: IsAuthorizedByMollieQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IsAuthorizedByMollieQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<IsAuthorizedByMollieQuery>(IsAuthorizedByMollieDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IsAuthorizedByMollie', 'query', variables);
        },
        GetBenefitForm(variables: GetBenefitFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitFormQuery>(GetBenefitFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefitForm', 'query', variables);
        },
        GetBenefit(variables: GetBenefitQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitQuery>(GetBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefit', 'query', variables);
        },
        GetBenefits(variables?: GetBenefitsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitsQuery>(GetBenefitsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefits', 'query', variables);
        },
        GetBenefitsByTierId(variables: GetBenefitsByTierIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitsByTierIdQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitsByTierIdQuery>(GetBenefitsByTierIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefitsByTierId', 'query', variables);
        },
        GetBenefitsBySubscriptionId(variables: GetBenefitsBySubscriptionIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitsBySubscriptionIdQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitsBySubscriptionIdQuery>(GetBenefitsBySubscriptionIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefitsBySubscriptionId', 'query', variables);
        },
        GetBenefitsBySubscriptionIdAndTierId(variables: GetBenefitsBySubscriptionIdAndTierIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitsBySubscriptionIdAndTierIdQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitsBySubscriptionIdAndTierIdQuery>(GetBenefitsBySubscriptionIdAndTierIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefitsBySubscriptionIdAndTierId', 'query', variables);
        },
        CreateBenefit(variables: CreateBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBenefitMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateBenefitMutation>(CreateBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBenefit', 'mutation', variables);
        },
        AddBenefitToTier(variables: AddBenefitToTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddBenefitToTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddBenefitToTierMutation>(AddBenefitToTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddBenefitToTier', 'mutation', variables);
        },
        RemoveBenefitForTier(variables: RemoveBenefitForTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveBenefitForTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveBenefitForTierMutation>(RemoveBenefitForTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveBenefitForTier', 'mutation', variables);
        },
        CreateBenefitForTier(variables: CreateBenefitForTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBenefitForTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateBenefitForTierMutation>(CreateBenefitForTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBenefitForTier', 'mutation', variables);
        },
        UpdateBenefit(variables: UpdateBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateBenefitMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateBenefitMutation>(UpdateBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBenefit', 'mutation', variables);
        },
        DeleteBenefit(variables: DeleteBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteBenefitMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteBenefitMutation>(DeleteBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteBenefit', 'mutation', variables);
        },
        GetCondition(variables: GetConditionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetConditionQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetConditionQuery>(GetConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCondition', 'query', variables);
        },
        GetConditions(variables?: GetConditionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetConditionsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetConditionsQuery>(GetConditionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetConditions', 'query', variables);
        },
        CreateCondition(variables: CreateConditionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateConditionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateConditionMutation>(CreateConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCondition', 'mutation', variables);
        },
        UpdateCondition(variables: UpdateConditionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateConditionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateConditionMutation>(UpdateConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCondition', 'mutation', variables);
        },
        DeleteCondition(variables: DeleteConditionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteConditionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteConditionMutation>(DeleteConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCondition', 'mutation', variables);
        },
        GetDiscountCode(variables: GetDiscountCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetDiscountCodeQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetDiscountCodeQuery>(GetDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetDiscountCode', 'query', variables);
        },
        GetDiscountCodes(variables?: GetDiscountCodesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetDiscountCodesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetDiscountCodesQuery>(GetDiscountCodesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetDiscountCodes', 'query', variables);
        },
        CreateDiscountCode(variables: CreateDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateDiscountCodeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateDiscountCodeMutation>(CreateDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDiscountCode', 'mutation', variables);
        },
        UpdateDiscountCode(variables: UpdateDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateDiscountCodeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateDiscountCodeMutation>(UpdateDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDiscountCode', 'mutation', variables);
        },
        DeleteDiscountCode(variables: DeleteDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteDiscountCodeMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteDiscountCodeMutation>(DeleteDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDiscountCode', 'mutation', variables);
        },
        GetExtra(variables: GetExtraQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtraQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetExtraQuery>(GetExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtra', 'query', variables);
        },
        GetExtrasBySubscriptionId(variables: GetExtrasBySubscriptionIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtrasBySubscriptionIdQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetExtrasBySubscriptionIdQuery>(GetExtrasBySubscriptionIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtrasBySubscriptionId', 'query', variables);
        },
        GetExtras(variables?: GetExtrasQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtrasQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetExtrasQuery>(GetExtrasDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtras', 'query', variables);
        },
        CreateExtra(variables: CreateExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateExtraMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateExtraMutation>(CreateExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateExtra', 'mutation', variables);
        },
        AddExtraToTier(variables: AddExtraToTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddExtraToTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddExtraToTierMutation>(AddExtraToTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddExtraToTier', 'mutation', variables);
        },
        UpdateExtra(variables: UpdateExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateExtraMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateExtraMutation>(UpdateExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateExtra', 'mutation', variables);
        },
        DeleteExtra(variables: DeleteExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteExtraMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteExtraMutation>(DeleteExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteExtra', 'mutation', variables);
        },
        GetSubscriptionForm(variables: GetSubscriptionFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionFormQuery>(GetSubscriptionFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptionForm', 'query', variables);
        },
        GetSubscription(variables: GetSubscriptionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionQuery>(GetSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscription', 'query', variables);
        },
        GetSubscriptions(variables?: GetSubscriptionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionsQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionsQuery>(GetSubscriptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptions', 'query', variables);
        },
        CreateSubscription(variables: CreateSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateSubscriptionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateSubscriptionMutation>(CreateSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSubscription', 'mutation', variables);
        },
        UpdateSubscription(variables: UpdateSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateSubscriptionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateSubscriptionMutation>(UpdateSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateSubscription', 'mutation', variables);
        },
        DeleteSubscription(variables: DeleteSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteSubscriptionMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteSubscriptionMutation>(DeleteSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteSubscription', 'mutation', variables);
        },
        GetTierForm(variables: GetTierFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTierFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetTierFormQuery>(GetTierFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTierForm', 'query', variables);
        },
        GetTier(variables: GetTierQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTierQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetTierQuery>(GetTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTier', 'query', variables);
        },
        GetTiersBySubscriptionId(variables: GetTiersBySubscriptionIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTiersBySubscriptionIdQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetTiersBySubscriptionIdQuery>(GetTiersBySubscriptionIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTiersBySubscriptionId', 'query', variables);
        },
        GetTiers(variables?: GetTiersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTiersQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetTiersQuery>(GetTiersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTiers', 'query', variables);
        },
        CreateTier(variables: CreateTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateTierMutation>(CreateTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTier', 'mutation', variables);
        },
        UpdateTier(variables: UpdateTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateTierMutation>(UpdateTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTier', 'mutation', variables);
        },
        DeleteTier(variables: DeleteTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteTierMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteTierMutation>(DeleteTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTier', 'mutation', variables);
        },
        AddTierToUser(variables: AddTierToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddTierToUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddTierToUserMutation>(AddTierToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddTierToUser', 'mutation', variables);
        },
        GetShoppingCart(variables?: GetShoppingCartQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetShoppingCartQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetShoppingCartQuery>(GetShoppingCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetShoppingCart', 'query', variables);
        },
        AddToShoppingCart(variables: AddToShoppingCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddToShoppingCartMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddToShoppingCartMutation>(AddToShoppingCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddToShoppingCart', 'mutation', variables);
        },
        RemoveTierFromShoppingCart(variables: RemoveTierFromShoppingCartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveTierFromShoppingCartMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveTierFromShoppingCartMutation>(RemoveTierFromShoppingCartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveTierFromShoppingCart', 'mutation', variables);
        },
        GetIdentities(variables?: GetIdentitiesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetIdentitiesQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetIdentitiesQuery>(GetIdentitiesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetIdentities', 'query', variables);
        },
        SetActiveMerchant(variables: SetActiveMerchantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SetActiveMerchantMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<SetActiveMerchantMutation>(SetActiveMerchantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetActiveMerchant', 'mutation', variables);
        },
        GetCurrentMerchant(variables?: GetCurrentMerchantQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCurrentMerchantQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetCurrentMerchantQuery>(GetCurrentMerchantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCurrentMerchant', 'query', variables);
        },
        RegisterMerchantContact(variables: RegisterMerchantContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterMerchantContactMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RegisterMerchantContactMutation>(RegisterMerchantContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegisterMerchantContact', 'mutation', variables);
        },
        RegisterMerchant(variables: RegisterMerchantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RegisterMerchantMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RegisterMerchantMutation>(RegisterMerchantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RegisterMerchant', 'mutation', variables);
        },
        GetRegisterMerchantForm(variables: GetRegisterMerchantFormQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRegisterMerchantFormQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetRegisterMerchantFormQuery>(GetRegisterMerchantFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRegisterMerchantForm', 'query', variables);
        },
        GetMerchantProfileProgress(variables: GetMerchantProfileProgressQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantProfileProgressQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantProfileProgressQuery>(GetMerchantProfileProgressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantProfileProgress', 'query', variables);
        },
        GetTjeckoSubscription(variables?: GetTjeckoSubscriptionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTjeckoSubscriptionQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetTjeckoSubscriptionQuery>(GetTjeckoSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTjeckoSubscription', 'query', variables);
        },
        GetTjeckoTier(variables?: GetTjeckoTierQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTjeckoTierQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetTjeckoTierQuery>(GetTjeckoTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTjeckoTier', 'query', variables);
        },
        GetMerchantTjeckoSubStatus(variables?: GetMerchantTjeckoSubStatusQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantTjeckoSubStatusQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantTjeckoSubStatusQuery>(GetMerchantTjeckoSubStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantTjeckoSubStatus', 'query', variables);
        },
        MerchantProfile(variables?: MerchantProfileQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MerchantProfileQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<MerchantProfileQuery>(MerchantProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MerchantProfile', 'query', variables);
        },
        GetUser(variables: GetUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetUserQuery>(GetUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUser', 'query', variables);
        },
        GetUsers(variables?: GetUsersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUsersQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetUsersQuery>(GetUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUsers', 'query', variables);
        },
        GetUserFormData(variables: GetUserFormDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserFormDataQuery> {
            return withWrapper((wrappedRequestHeaders) => client.request<GetUserFormDataQuery>(GetUserFormDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUserFormData', 'query', variables);
        },
        CreateUser(variables: CreateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUser', 'mutation', variables);
        },
        UpdateUser(variables: UpdateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserMutation>(UpdateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUser', 'mutation', variables);
        },
        DeleteUser(variables: DeleteUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<DeleteUserMutation>(DeleteUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteUser', 'mutation', variables);
        },
        AddAddressToUser(variables: AddAddressToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddAddressToUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddAddressToUserMutation>(AddAddressToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddAddressToUser', 'mutation', variables);
        },
        AddCompanyToUser(variables: AddCompanyToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddCompanyToUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AddCompanyToUserMutation>(AddCompanyToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddCompanyToUser', 'mutation', variables);
        },
        AcceptTermsForUser(variables: AcceptTermsForUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AcceptTermsForUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<AcceptTermsForUserMutation>(AcceptTermsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AcceptTermsForUser', 'mutation', variables);
        },
        RemoveAddressFromUser(variables: RemoveAddressFromUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveAddressFromUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveAddressFromUserMutation>(RemoveAddressFromUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveAddressFromUser', 'mutation', variables);
        },
        RemoveCompanyFromUser(variables: RemoveCompanyFromUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveCompanyFromUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<RemoveCompanyFromUserMutation>(RemoveCompanyFromUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCompanyFromUser', 'mutation', variables);
        },
        UnacceptTermsForUser(variables: UnacceptTermsForUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UnacceptTermsForUserMutation> {
            return withWrapper((wrappedRequestHeaders) => client.request<UnacceptTermsForUserMutation>(UnacceptTermsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UnacceptTermsForUser', 'mutation', variables);
        }
    };
}
export type Sdk = ReturnType<typeof getSdk>;