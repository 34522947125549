import React, {useMemo} from 'react';
import ShowPage from "@templates/page/ShowPage.template";
import useParamId from "@hooks/useParamId";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import TierRevenueChart from "@pages/merchant/tiers/components/TierRevenueChart";
import BenefitsSection from "@pages/merchant/tiers/components/BenefitsSection";
import {NavRouteItem} from "@components/navigation/NavbarTop/NavbarTop.context";


const TierShowPage = () => {

    const id = useParamId();
    const gql = useGql()

    /**
     * Current tier.
     */
    const tier = useAsyncMemo(async () => {
        return await gql.GetTier({id}).then(r => r.tier)
    }, [])

    /**
     * Current subscription.
     */
    const subscription = useAsyncMemo(async () => {
        if (!tier?.subscriptionId) {
            return undefined
        }
        return await gql.GetSubscription({id: tier.subscriptionId}).then(r => r.subscription)
    }, [tier?.subscriptionId])

    /**
     * Set name of subscription for back route.
     */
    const navBackRouteItem = useMemo((): NavRouteItem | undefined => {
        if (!subscription) {
            return undefined
        }

        return {
            title: subscription.title,
            path: `/subscriptions/${subscription.id}`
        }
    }, [subscription]);

    return (
        <ShowPage back={navBackRouteItem} pageTitle={tier?.title}>
            <TierRevenueChart/>
            <BenefitsSection subscriptionId={subscription?.id} tierId={id}/>
        </ShowPage>
    );
};

export default TierShowPage;
