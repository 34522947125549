import {ChartDataPoint} from "@components/chart/ChartData";
import moment from "moment/moment";
import {BillingPaymentStatus} from "@pages/merchant/settings/billing/components/BillingBadge";


export type Subscription =
    'Basic' | 'Premium' | 'Pro';


export type SubscriberItem = {
    id: string;
    title: string;
    date: string;
    subscriptionType: Subscription
    amount: number
    status: BillingPaymentStatus;
}

export const mockSubscriberData: SubscriberItem[] = [
    {
        id: '1',
        title: 'Ethan Johnson',
        date: '2023-07-10 09:00:00',
        subscriptionType: 'Basic',
        amount: 9.99,
        status: 'paid'
    },
    {
        id: '2',
        title: 'Amber Wright',
        date: '2023-07-11 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '3',
        title: 'Benjamin Lee',
        date: '2023-08-12 09:00:00',
        subscriptionType: 'Pro',
        amount: 15.99,
        status: 'reversed'
    },
    {
        id: '4',
        title: 'Alexander White',
        date: '2023-08-13 09:00:00',
        subscriptionType: 'Basic',
        amount: 9.99,
        status: 'paid'
    },
    {
        id: '5',
        title: 'Mia Martinez',
        date: '2023-08-14 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '6',
        title: 'Sophia Rodriguez',
        date: '2023-10-15 09:00:00',
        subscriptionType: 'Pro',
        amount: 15.99,
        status: 'reversed'
    },
    {
        id: '7',
        title: 'Isabella Taylor',
        date: '2023-10-16 09:00:00',
        subscriptionType: 'Basic',
        amount: 19.99,
        status: 'paid'
    },
    {
        id: '8',
        title: 'Oliver Harris',
        date: '2023-11-17 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '9',
        title: 'Charlotte Wilson',
        date: '2023-12 09:00:00',
        subscriptionType: 'Pro',
        amount: 15.99,
        status: 'reversed'
    },
    {
        id: '10',
        title: 'Harper Moore',
        date: '2023-12 09:00:00',
        subscriptionType: 'Basic',
        amount: 9.99,
        status: 'paid'
    },
    {
        id: '11',
        title: 'Abigail Garcia',
        date: '2023-12 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '12',
        title: 'John Doe',
        date: '2024-01-21 09:00:00',
        subscriptionType: 'Pro',
        amount: 15.99,
        status: 'reversed'
    },
    {
        id: '13',
        title: 'Maria Wong',
        date: '2024-01-22 09:00:00',
        subscriptionType: 'Basic',
        amount: 9.99,
        status: 'paid'
    },
    {
        id: '14',
        title: 'Nathan Diaz',
        date: '2024-01-23 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '15',
        title: 'Oliver Park',
        date: '2024-01-24 09:00:00',
        subscriptionType: 'Pro',
        amount: 15.99,
        status: 'reversed'
    },
    {
        id: '16',
        title: 'Paula Allen',
        date: '2024-01-24 09:00:00',
        subscriptionType: 'Basic',
        amount: 9.99,
        status: 'paid'
    },
    {
        id: '17',
        title: 'Quincy Jones',
        date: '2024-01-25 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '18',
        title: 'Bryan Green',
        date: '2024-02-26 09:00:00',
        subscriptionType: 'Pro',
        amount: 15.99,
        status: 'failed'
    },
    {
        id: '19',
        title: 'Amber Rogers',
        date: '2024-02-27 09:00:00',
        subscriptionType: 'Basic',
        amount: 9.99,
        status: 'paid'
    },
    {
        id: '20',
        title: 'John Doe',
        date: '2024-03-28 09:00:00',
        subscriptionType: 'Premium',
        amount: 2.99,
        status: 'paid'
    },

    {
        id: '21',
        title: 'Ethana Johnsons',
        date: '2024-04-01 09:00:00',
        subscriptionType: 'Basic',
        amount: 9.99,
        status: 'failed'
    },
    {
        id: '22',
        title: 'Emma Davis',
        date: '2024-04-03 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'reversed'
    },
    {
        id: '23',
        title: 'Benjamin Custard',
        date: '2024-04-04 09:00:00',
        subscriptionType: 'Pro',
        amount: 15.99,
        status: 'paid'
    },

    {
        id: '24',
        title: 'Bjorn Doe',
        date: '2024-04-05 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'paid'
    },
    {
        id: '25',
        title: 'John Door',
        date: '2024-05-06 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'paid'
    },
    {
        id: '26',
        title: 'Johan Does',
        date: '2024-05-07 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '27',
        title: 'Jacob Dunn',
        date: '2024-05-08 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'reversed'
    },
    {
        id: '28',
        title: 'Jared Swan',
        date: '2024-05-09 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'failed'
    },
    {
        id: '28',
        title: 'Willy Wonka',
        date: '2024-06-10 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'paid'
    },
    {
        id: '30',
        title: 'Davey Jones',
        date: '2024-06-11 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'paid'
    },
    {
        id: '31',
        title: 'Arnold Chan',
        date: '2024-06-12 09:00:00',
        subscriptionType: 'Premium',
        amount: 12.99,
        status: 'paid'
    },
];


export const dummySubscriberChartData = (): ChartDataPoint[] => {
    const items: ChartDataPoint[] = [];

    mockSubscriberData.map(data => ({
        x: data.date,
        y: 1 // Initial?
    })).forEach(item => {
        const month = moment(item.x).format('YYYY-MM').concat('-01');
        const groupIndex = items.findIndex(item => item.x === month);

        // New Entry?
        if (groupIndex < 0) {
            items.push({x: month, y: item.y});
            return;
        }

        // Entry already Exist?
        items[groupIndex] = {
            ...items[groupIndex],
            y: items[groupIndex].y + item.y
        };
    });

    return items
        .map(item => ({
            ...item,
            y: Math.round(item.y * 100) / 100
        }))
        .sort((a, b) => moment(a.x).diff(moment(b.x)));
}

const getMembersInMonth = (yyyy_mm: string) => {
    return mockSubscriberData
        .filter(item => moment(item.date).isSameOrBefore(yyyy_mm, 'month'))
        .length
}

const percentageChanged = (prevAmount: number, newAmount: number) => {
    return Math.round((((newAmount - prevAmount) / prevAmount) * 100) * 10) / 10;
}

export const dummySubscriberData = (start = '2024-06', end = '2024-07') => {
    const prevMonth = getMembersInMonth(start);
    const currentMonth = getMembersInMonth(end);
    return ({
        prevAmount: prevMonth,
        currentAmount: currentMonth,
        percentageChanged: percentageChanged(prevMonth, currentMonth)
    })
}
