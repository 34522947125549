import React, {FC, useRef} from 'react';
import useGql from "@modules/graphql/useGql";
import {FormFields} from "@components/form/fields/FormField.component";
import {RegisterMerchantInput, RegisterMerchantMutation} from "@src/gql-schema";
import {z} from "zod";
import Form from "@components/form/Form.component";
import useAsyncMemo from "@hooks/useAsyncMemo";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import UserIcon from "@components/icon/UserIcon";
import PrimaryButton from "@components/button/PrimaryButton";


const MerchantRegisterPage: FC = () => {


    const gql = useGql();
    const formRef = useRef<HTMLFormElement>(null)

    const email = useAsyncMemo(async () => {
        return await gql.GetIdentities().then(r => r.identities.user.email)
    }, [])

    const fields: FormFields<RegisterMerchantInput> = {
        email: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Email',
            defaultValue: email,
            disabled: true
        },
        name: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Full name',
        }
    }

    const onSubmitSuccess = (t: RegisterMerchantMutation) => {
        console.log({t})
    }

    return (
        <ShowPageTemplate>
            <div className={'rounded-full p-2 border border-neutral-500 mx-auto w-fit mt-12'}>
                <UserIcon/>
            </div>
            <Form
                ref={formRef}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                submit={gql.RegisterMerchant}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                onSubmitSuccess={onSubmitSuccess}
                fields={fields}
                payloadInjection={{}}
            />

            <PrimaryButton className={'mt-4'} onClick={() => formRef.current?.requestSubmit()}>Submit</PrimaryButton>
        </ShowPageTemplate>
    )
};
export default MerchantRegisterPage;
