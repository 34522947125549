import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import {CheckIcon} from "@radix-ui/react-icons"

import {cn} from "@shadcn/lib/utils"

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({className, ...props}, ref) => (
    <div className={'h-[.1625rem] inline-block'}>
        <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
                'h-4 w-4 relative',
                'rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
                'disabled:opacity-50 data-[state=checked]:bg-white',
                'text-primary',
                "disabled:cursor-not-allowed",
                className
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator
                className={cn("flex items-center justify-center text-primary-600 absolute top-[-0.3rem] left-[-0.3rem]")}
            >
                <CheckIcon className="h-6 w-6"/>
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
    </div>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export {Checkbox}
