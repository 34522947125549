import React, {FC} from 'react';
import {ImplementFormPropsWithId} from "@components/form/Form.component";
import {UpdateExtraInput, UpdateExtraMutation} from "@src/gql-schema";
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import useGql from "@modules/graphql/useGql";
import {OpenState} from "@app/types/States";
import {ExtraTypeOptions, ExtraWhenOptions} from "@app/entities/Extra.types";

const ExtraUpdateTestForm: FC<ImplementFormPropsWithId<UpdateExtraMutation> & {
    openState: OpenState
}> = ({
    id,
    onSubmitSuccess,
    openState
}) => {
    const gql = useGql();

    const fields: FormFields<UpdateExtraInput> = {
        title: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'input',
            label: 'Title',
        },

        value: {
            zodType: z.number({coerce: true}).min(0.01).step(0.01).optional(),
            fieldType: 'input',
            label: 'Amount',
            colSpan: 'col-span-3'
        },

        type: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'select',
            label: 'Type',
            options: ExtraTypeOptions,
            colSpan: 'col-span-4'
        },
        when: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'select',
            label: 'When',
            options: ExtraWhenOptions,
            colSpan: 'col-span-5'
        },
        description: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'textarea',
            label: 'Description'
        },
        active: {
            zodType: z.boolean().optional(),
            fieldType: 'checkbox',
            label: 'Active',
            defaultValue: true
        },
    }

    return (
        <>
            <PullOverForm
                title={'Extra test form'}
                openState={openState}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    id,
                    getData: async () => gql.GetExtra({id}).then(r => r.extra),
                    submit: gql.UpdateExtra,
                    onSubmitSuccess: onSubmitSuccess,
                    fields: fields,
                    payloadInjection: {}
                }}
            />
        </>
    );
};

export default ExtraUpdateTestForm;
