import React, {useRef, useState} from 'react'
import {z} from "zod";
import CheckOutFormComponent from "@pages/merchant/checkout/components/CheckOutFormComponent";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import {useNavigate} from "react-router-dom";
import CheckoutAgreement from "@pages/merchant/checkout/index/components/CheckoutAgreement.component";
import useAsyncMemo from "@hooks/useAsyncMemo";
import {MinimumTosFragment, PaymentMethod} from "@src/gql-schema";
import useGql from "@modules/graphql/useGql";
import useAuth from "@hooks/useAuth";
import {CheckoutFailedAlert} from "@pages/merchant/checkout/index/components/CheckoutFailedAlert";
import {Button} from "@src/@/components/ui/button";

// Part of my workaround for form submit
export type FormRef = {
    submit: () => void
}

export const formSchema = z.object({
    paymentMethod: z.enum(['SEPA', 'Credit Card', 'PayPal']),
    cardNumber: z.string().min(1, {
        message: 'Card number must be at least 1 character.'
    }),
    cardHolder: z.string().min(1, {
        message: 'Card holder must be at least 1 character.'
    }),
    expirationDate: z.string().min(1, {}),
    cvv: z.string().min(3),
    agreeToTerms: z.boolean()
})

export const labels = ['Payment Method', 'Card number', 'Card Holder', 'Expiration Date', 'CVV'];
export const paymentMethods = ['SEPA', 'Credit Card', 'PayPal'];
const paymentMethodMap = {
    'SEPA': PaymentMethod.Sepa,
    'Credit Card': PaymentMethod.CreditCard,
    'PayPal': PaymentMethod.Sepa // Temp Fix
} as const;

const CheckoutIndexPage: React.FC = () => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Credit Card')

    const formRef = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();
    const gql = useGql();
    const auth = useAuth();

    const tos: MinimumTosFragment | undefined = useAsyncMemo(async () => {
        return (await gql.LatestTerms()).latestTerms;
    }, [])

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            paymentMethod: 'Credit Card',
            cardNumber: '',
            cardHolder: '',
            expirationDate: '',
            cvv: '',
            agreeToTerms: false,
        }
    })
    const [canProceed, setCanProceed] = useState(false)
    const [values, setValues] = useState<z.infer<typeof formSchema>>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false)

    const data = useAsyncMemo(async () => {

        const cart = (await gql.GetShoppingCart()).getShoppingCart
        const tier = (await gql.GetTier({id: cart?.[0].TierID ?? ''})).tier

        return {cart, tier}
    }, [])

    function handleOnSubmit(values: z.infer<typeof formSchema>) {
        setValues(values)
    }

    function handleChange(values: z.infer<typeof formSchema>) {
        if (values.cvv.trim() === ''
            || values.cardNumber.trim() === ''
            || values.cardHolder.trim() === ''
            || values.expirationDate.trim() === '') {
            setCanProceed(false)
        } else {
            setCanProceed(true)
        }
    }

    // Part of my workaround for form submit
    const formRef2 = useRef<FormRef>(null)
    const handleSubmit = () => {
        formRef2.current?.submit()
        console.log(values) // Undefined
        const test = form.getValues()
        if (auth.userId && test) {
            const findPaymentMethod = paymentMethodMap[selectedPaymentMethod as keyof typeof paymentMethodMap]
            gql.UpdateUser({
                id: auth.userId,
                input: {
                    paymentMethod: findPaymentMethod
                }
            })
                .then(() => {
                    console.log(`UserID: ${auth?.userId}`)
                    console.log(`TOSID: ${tos?.id}`)
                    gql.AcceptTermsForUser({
                        record: auth?.userId ?? '8c1856a1-9495-493e-92cc-d314b63ca4f8',
                        relation: tos?.id ?? '7d05fec4-824b-4809-a38a-274affbac703'
                    })
                        .then((r) => {
                            console.log(r)
                            console.log(`TierID: ${data?.cart?.[0].TierID}`)
                            console.log(`UserID: ${data?.cart?.[0].UserID}`)
                            console.log(`TierInterval: ${data?.cart?.[0].IntervalID}`)
                            setTimeout(() => {
                                gql.AddTierToUser({ // Not Working correctly yet, 'Probably a persistency issue'
                                    relation: data?.cart?.[0].TierID ?? '6bbded18-b9a7-4b3d-9d8f-f64563c7480e',
                                    record: data?.cart?.[0].UserID ?? '8c1856a1-9495-493e-92cc-d314b63ca4f8',
                                    interval: data?.cart?.[0].IntervalID ?? '99caebfd-afe1-4263-81ac-d3cb47fccea9'
                                })
                                    .then((r) => {
                                        console.log(r)
                                        navigate('success')
                                    }).catch(err => {

                                        console.error(`AddToTier: ${err}`)
                                    })
                            }, 5000)
                        })
                        .catch(err => {
                            console.error(`AcceptTermsForUser: ${err}`)
                        })
                })
                .catch(err => console.error(err))
        }
    }

    return <ShowPageTemplate>
        {
            !data?.tier ?
                <div>Loading ...</div>
                :
                <>
                    <div className={'flex flex-col items-center mb-4 text-neutral-900'}>
                        {
                            isPaymentFailed &&
                            <CheckoutFailedAlert/>
                        }
                        <div className={'flex-col flex w-full items-center my-8 gap-y-2'}>
                            <p className={'text-3xl font-semibold'}>{data.tier.title}</p>
                            <p className={'text-sm'}>{data.tier.description}</p>
                            <p className={'text-xl font-medium'}>Total</p>
                            <p className={'text-5xl font-semibold'}>{`€${data.tier.price}`}</p>
                        </div>
                        <CheckOutFormComponent
                            ref={formRef}
                            // Part of my workaround for form submit
                            ref2={formRef2}
                            form={form}
                            paymentMethods={paymentMethods}
                            buttonText={'Checkout'}
                            onChange={handleChange}
                            onSubmit={handleOnSubmit}
                            selectedPaymentMethod={selectedPaymentMethod}
                            setSelectedPaymentMethod={setSelectedPaymentMethod}
                        />
                    </div>
                    <CheckoutAgreement onSubmit={handleSubmit} agreement={tos} disabled={!canProceed}/>
                    <Button onClick={() => setIsPaymentFailed(!isPaymentFailed)}>Test Button for Payment Failed</Button>
                </>
        }
    </ShowPageTemplate>
}

export default CheckoutIndexPage