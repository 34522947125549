import React from 'react'
import {Button} from "@src/@/components/ui/button";

const PayPalFormComponent: React.FC = () => {
    return (
        <div>
            <Button className={'text-white w-full gap-x-2 py-5 bg-[#0070BA]'}>
                <span className={'text-xl'}>
                Connect to PayPal
                </span>
            </Button>
        </div>
    )
}
export default PayPalFormComponent