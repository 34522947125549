import React, {
    DetailedHTMLProps,
    Dispatch,
    forwardRef,
    HTMLAttributes, Ref,
    SetStateAction,
    useImperativeHandle
} from "react";
import {Form, FormItem,} from "@src/@/components/ui/form"
import {UseFormReturn} from "react-hook-form";
import {z} from "zod";
import {FormRef, formSchema} from "@pages/merchant/checkout/index/CheckOut.index.page";
import InputSameLineComponent from "./InputSameLineComponent";
import InputRadioComponent from "./InputRadioComponent";
import InputCheckOutComponent from "./InputCheckOutComponent";
import SEPAFormComponent from "./SEPAFormComponent";
import PayPalFormComponent from "./PayPalFormComponent";


interface CheckOutFormComponentProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onSubmit' | 'onChange'> {
    form: UseFormReturn<z.infer<typeof formSchema>>,
    paymentMethods: string[],
    onSubmit: (values: z.infer<typeof formSchema>) => void,
    onChange: (values: z.infer<typeof formSchema>) => void,
    buttonText: string,
    ref: React.MutableRefObject<HTMLFormElement>,
    ref2: Ref<FormRef>,
    selectedPaymentMethod: string,
    setSelectedPaymentMethod: Dispatch<SetStateAction<string>>
}

export type FormSchemaKeys = keyof typeof formSchema['_def']['shape'];

const CheckOutFormComponent: React.ForwardRefRenderFunction<HTMLFormElement, CheckOutFormComponentProps> = ({
    form,
    paymentMethods,
    onSubmit,
    onChange,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    ref2,
    ...props
}, ref) => {
    // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Credit Card')

    /* temp solution */
    const formSchemaProperty: FormSchemaKeys[] = Object.keys(formSchema.shape) as FormSchemaKeys[]

    /* */

    function handleOnError(error: object) {
        console.log(error)
    }

    // Part of my workaround for form submit
    useImperativeHandle(ref2, () => ({
        submit: () => {
            form.trigger().then(isValid => {
                console.log(isValid)
                if (isValid) {
                    form.handleSubmit(onSubmit)()
                }
            })
        }
    }))

    return (
        <Form {...props} {...form}>
            <form ref={ref} onSubmit={(e) => {
                e.preventDefault()
                form.handleSubmit(onSubmit, handleOnError)
            }} onChange={(e) => {
                e.preventDefault()
                onChange(form.getValues())
            }}
            className={'w-full flex flex-col flex-wrap space-y-5'}>
                <InputRadioComponent form={form} options={paymentMethods} formName={formSchemaProperty[0]}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    label={'Payment Method'}/>
                <>
                    {
                        selectedPaymentMethod === 'Credit Card' ?
                            <FormItem className={'flex flex-col space-y-0 gap-y-2 items-center flex-wrap'}>
                                {
                                    ['Card Number', 'Card Holder'].map((label, index) => {
                                        return <InputCheckOutComponent key={index} form={form} label={label}
                                            formName={formSchemaProperty[index + 1]}/>
                                    })
                                }
                                <InputSameLineComponent form={form} formName1={formSchemaProperty[3]}
                                    formName2={formSchemaProperty[4]}
                                    label1={'Expiration Date'}
                                    label2={'CVV'}
                                    containerClass={'flex gap-x-2 items-center space-y-0 w-full'}
                                    firstInputClass={'flex-grow'} secondInputClass={'w-3/12'}/>
                            </FormItem>
                            : selectedPaymentMethod === 'SEPA' ?
                                <SEPAFormComponent/>
                                : <PayPalFormComponent/>
                    }
                </>
            </form>
        </Form>
    )
};

CheckOutFormComponent.displayName = 'CheckOutFormComponent'
export default forwardRef(CheckOutFormComponent)