import React from 'react';
import GuestPageTemplate from "@templates/page/GuestPage.template";
import SuccessIcon from "@components/icon/success.icon";
import {Link} from "react-router-dom";
import PrimaryButton from "@components/button/PrimaryButton";
import Confetti from "react-confetti";

const CheckoutSuccessPage: React.FC = () => {
    return (
        <GuestPageTemplate>
            <div className={'grid gap-y-10 h-[16rem] translate-y-[calc(50vh-10.666rem-1rem)]'}>
                <div className={'mx-auto w-fit'}>
                    <SuccessIcon/>
                </div>
                <div className={'text-center'}>
                    <p className={'text-xl font-semibold'}>Payment Succeeded</p>
                </div>
                <div>
                    <Link className={'w-full'} to={'/onboarding'}>
                        <PrimaryButton>Start Onboarding</PrimaryButton>
                    </Link>
                </div>
            </div>

            <Confetti
                recycle={false}
                width={window.screen.width}
                height={window.screen.height}
            />
        </GuestPageTemplate>
    )
}

export default CheckoutSuccessPage