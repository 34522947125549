import React, {ForwardedRef, JSX, useMemo} from "react";
import {baseObjectOutputType, ZodRawShape} from 'zod/lib/types';
import {objectUtil, z, ZodObject} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm, UseFormReturn} from "react-hook-form";

import {Form as ShadCnForm,} from '@shadcn/components/ui/form';
import useAsyncMemo from "@hooks/useAsyncMemo";
import {Exact} from "@src/gql-schema";
import {GridColSpan} from "@modules/tailwind/Tailwind.module";
import FormField, {FormFieldItem, FormFields, SelectFormField} from "@components/form/fields/FormField.component";
import Input from "@components/form/fields/Input.component";
import Select from "@components/form/fields/Select.component";
import Toggle from "@components/form/fields/Toggle.component";
import Checkbox from "@components/form/fields/Checkbox.component";
import {GraphQLClientRequestHeaders} from "graphql-request/build/cjs/types";
import TextArea from "@components/form/fields/TextArea.component";

/**
 * Sharded definition for form props, this will be used for the props of a form implementation like "FooForm"
 */
type ImplementFormPropsBase<Mutation> = {
    /** Will be called after the form is submitted successfully */
    onSubmitSuccess?: (mutation: Mutation | undefined) => Promise<void> | void
}

/**
 * When creating a form like "FooForm" use this type in the props.
 * Use case: create forms
 */
export type ImplementFormProps<Mutation> = ImplementFormPropsBase<Mutation>;

/**
 * When creating a form like "FooForm" and an ID is needed, use this type in the props.
 * Use case: update forms
 */
export type ImplementFormPropsWithId<Mutation> = ImplementFormProps<Mutation> & { id: string }

/**
 * Base fields used for creating a form implementation like "PullOverForm"
 */
type ExtendFormPropsBase<
    Fields,
    PayloadInjection,
    Mutation
> = ImplementFormPropsBase<Mutation> & {
    /** Object containing the field names as key and the FormField as value. */
    fields: FormFields<Fields>,
    /** Properties that will be injected into the payload, will provide required properties if not present in Field set. */
    payloadInjection: PayloadInjection,
}

/**
 * Payload blueprint for creating a resource.
 */
export type CreatePayload<Payload> = Exact<{ input: Payload }>

/**
 * Payload blueprint for updating a resource.
 */
export type UpdatePayload<Payload> = Exact<{ id: string, input: Payload }>


type CreateProps<Fields, Payload, Mutation> = {
    id?: never
    /** Optional data fetcher for fields, will provide the value to a field if the property key match */
    getData?: () => Promise<Fields | undefined>,
    /** Method for submitting the form as Create request. */
    submit: (variables: CreatePayload<Payload>, requestHeaders?: GraphQLClientRequestHeaders | undefined) => Promise<Mutation>
}

type UpdateProps<Fields, Payload, Mutation> = {
    /** ID of the resource that is currently edited. */
    id?: string
    /** Data fetcher for fields, will provide the value to a field if the property key match */
    getData: () => Promise<Fields>,
    /** Method for submitting the form as Update request. */
    submit: (variables: UpdatePayload<Payload>, requestHeaders?: GraphQLClientRequestHeaders | undefined) => Promise<Mutation>
}

type FormTypeProps<Fields, Payload, Mutation> =
    | CreateProps<Fields, Payload, Mutation>
    | UpdateProps<Fields, Payload, Mutation>

/**
 * @fixme ternary prop types
 * Used for creating a form implementation like "PullOverForm".
 *
 * Includes a switch for create and update properties.
 */
export type ExtendFormProps<
    Fields,
    Payload,
    PayloadInjection,
    Mutation
> = ExtendFormPropsBase<Fields, PayloadInjection, Mutation> & FormTypeProps<Fields, Payload, Mutation>

/**
 * Props for the Form component.
 */
type Props<
    Fields,
    Payload,
    PayloadInjection,
    Mutation
> = ImplementFormProps<Mutation>
    & ExtendFormProps<Fields, Payload, PayloadInjection, Mutation>

/**
 * Component responsible for creating and updating resources.
 *
 * @template Fields Form fields that a user needs to fill in.
 * @template Payload Request object expected by backend when submitting the form.
 * Combination of "Fields" + "PayloadInjection"
 * @template PayloadInjection Properties that are expected in "Payload" but not present in "Fields".
 * @template Mutation Response from graphql after submit.
 *
 * Typedefs used for hinting in code, Webstorm does not like this...
 * @typedef Fields
 * @typedef Payload
 * @typedef PayloadInjection
 * @typedef Mutation
 *
 * @param {Props<Fields, Payload, PayloadInjection, Mutation>} props
 * @param {ForwardedRef<HTMLFormElement>} ref
 * @returns {JSX.Element}
 */
const Form = <
    Fields,
    Payload extends Fields,
    PayloadInjection extends Omit<Payload, (keyof Fields extends never ? keyof Payload : keyof Fields)>,
    Mutation
>(props: Props<Fields, Payload, PayloadInjection, Mutation>, ref: ForwardedRef<HTMLFormElement>): JSX.Element => {

    /** Zod Shape preparation for retrieving the Zod Type */
    type Shape = FormFields<Fields>;

    /** Typedef responsible for providing the correct type for Zod */
    type ZodShape = { [k in keyof Shape]: Shape[keyof Shape]['zodType'] }

    /** Signature for providing context for React Form Hook */
    type ShapeSignature = z.infer<ZodObject<ZodShape>>;

    /** Preparation for DefaultValuesSignature */
    type DefaultValueType = objectUtil.addQuestionMarks<baseObjectOutputType<ZodShape>>;

    /** Signature for provided default values */
    type DefaultValuesSignature = { [k in keyof DefaultValueType]: DefaultValueType[k]; }

    /**
     * Prop destruction
     */
    const {
        getData,
        fields,
        payloadInjection,
        submit,
        onSubmitSuccess
    } = props;

    /**
     * ID of the provided resource.
     */
    const id: string | undefined = useMemo((): string | undefined => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return props?.id ?? undefined
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
    }, [props['id']]);

    /**
     * Data object corresponding to the shape of Fields.
     */
    const data: Fields | undefined = useAsyncMemo(async (): Promise<Fields | undefined> => {
        return getData ? await getData() : undefined
    }, [getData])

    /**
     * Shape for Zod definitions.
     */
    const zodShape: ZodRawShape = useMemo((): ZodRawShape => Object.keys(fields).reduce((shape, k) => ({
        ...shape,
        [k]: fields[k as keyof Fields].zodType
    }) as ZodRawShape, {} as ZodRawShape), [fields]);

    /**
     * Form schema created from Zod object.
     */
    const formSchema: ZodObject<ZodShape> = useMemo((): ZodObject<ZodShape> => {
        return z.object(zodShape) as ZodObject<ZodShape>
    }, [zodShape, fields]);

    /**
     * Default values matching field properties.
     */
    const defaultValues: DefaultValuesSignature = useMemo((): DefaultValuesSignature => {
        const defaultValues = {} as DefaultValuesSignature;
        return Object.keys(fields).reduce((values, k) => ({
            ...values,
            [k]: data?.[k as keyof Fields] ?? fields[k as keyof Fields]?.defaultValue ?? ''
        }) as DefaultValuesSignature, defaultValues);
    }, [fields, data]);

    /**
     * React form hook implementation.
     */
    const form: UseFormReturn<ShapeSignature> = useForm<ShapeSignature>({
        resolver: zodResolver(formSchema),
        values: defaultValues
    });

    /**
     * Submit form if validated.
     *
     * @param {Fields} values
     * @returns {Promise<void>}
     */
    const handleSubmit = async (values: Fields): Promise<void> => {
        const input = {...values, ...payloadInjection} as Payload;
        const payload = {input: input} as CreatePayload<Payload> | UpdatePayload<Payload>;
        if (id) {
            (payload as UpdatePayload<Payload>)['id'] = id;
        }
        console.log(payload)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await submit(payload)
            .then(async res => await onSubmitSuccess?.(res)).catch(err => console.error(err))
    }

    /**
     * Get form field element by Field key.
     * @param {string} k
     */
    const formItem = (k: string) => {
        const formField = fields[k as keyof Fields];
        const colSpan: GridColSpan | GridColSpan[] = formField.colSpan ?? 'col-span-12';
        const colSpanClassNames: string = Array.isArray(colSpan) ? colSpan.join(' ') : colSpan;

        const baseFieldItem: Partial<FormFieldItem> = {
            className: colSpanClassNames,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formField,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            props: {
                name: k,
            }
        }

        switch (formField.fieldType) {
            case 'select':
                return {
                    ...baseFieldItem,
                    Component: Select,
                    props: {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ...baseFieldItem.props,
                        options: (formField as SelectFormField).options
                    }
                }

            case 'toggle':
                return {
                    ...baseFieldItem,
                    Component: Toggle,
                    props: {
                        name: k,
                    }
                }

            case 'checkbox':
                return {
                    ...baseFieldItem,
                    Component: Checkbox,
                }

            case 'textarea':
                return {
                    ...baseFieldItem,
                    Component: TextArea,
                }

            default:
                return {
                    ...baseFieldItem,
                    Component: Input,
                }
        }
    }

    return <>
        <ShadCnForm {...form}>
            <form
                ref={ref}
                onSubmit={form.handleSubmit(async (values) => handleSubmit(values as Fields))}
                className={'grid grid-cols-12 gap-y-4 gap-x-2'}
            >
                {Object.keys(formSchema.shape).map((k) => {
                    const isSideBySide =
                        (k === 'firstName' || k === 'lastName') ||
                        (k === 'city' || k === 'postalCode');

                    return (
                        <div
                            key={k}
                            className={isSideBySide ? 'col-span-6' : 'col-span-12'}
                        >
                            <FormField
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                control={form.control}
                                name={k}
                                item={formItem(k) as unknown as FormFieldItem}
                            />
                        </div>
                    );
                })}
            </form>
        </ShadCnForm>
    </>
}
export default React.forwardRef(Form)