import React, {FC, ReactNode} from 'react'

type Props = {
    children: ReactNode

}

const ManageSubscriptionPullover: FC<Props> = () => {
    // const [openMangeSubscription, setManageSubscription] = useState<boolean>(false)


    return (
        <>
            {/*<PullOver*/}
            {/*    variations={'max-height'}*/}
            {/*    title={'Manage Subscription'}*/}
            {/*    trigger={*/}
            {/*        {*/}
            {/*            title: children,*/}
            {/*            onClick: () => setManageSubscription(true),*/}
            {/*            variation: 'link'*/}
            {/*        }*/}
            {/*    }*/}
            {/*    openState={[openMangeSubscription, setManageSubscription]}*/}
            {/*>*/}
            {/*    <div className={'flex flex-col gap-y-4'}>*/}
            {/*        <EditSubscriptionPullover/>*/}
            {/*        <CancelSubscriptionPullover/>*/}
            {/*    </div>*/}
            {/*</PullOver>*/}
        </>
    )
}

export default ManageSubscriptionPullover