import {BillingPaymentStatus} from "@pages/merchant/settings/billing/components/BillingBadge";
import {ChartDataPoint} from "@components/chart/ChartData";
import moment from "moment";

export type PaymentItem = {
    id: string;
    title: string;
    status: BillingPaymentStatus;
    amount: number;
    date: string;
}

export const mockPaymentData: PaymentItem[] = [
    {id: '27', title: 'John Doe', status: 'paid', amount: 98.99, date: '2024-07-03 00:00:00'},
    {id: '26', title: 'Bryan Williams', status: 'failed', amount: 84.99, date: '2024-07-04 00:01:00'},
    {id: '25', title: 'Amber Brown', status: 'reversed', amount: 74.99, date: '2024-07-05 00:02:00'},
    {id: '24', title: 'Emma Bridge', status: 'failed', amount: 82.99, date: '2024-07-06 00:03:00'},
    {id: '23', title: 'James Clarkson', status: 'paid', amount: 76.99, date: '2024-07-03 00:04:00'},

    {id: '270', title: 'John Doe', status: 'paid', amount: 24.99, date: '2024-06-15 07:00:00'},
    {id: '260', title: 'Bryan Williams', status: 'failed', amount: 35.99, date: '2024-06-12 00:01:00'},
    {id: '250', title: 'Amber Brown', status: 'reversed', amount: 42.99, date: '2024-06-15 02:30:00'},
    {id: '240', title: 'Emma Bridge', status: 'failed', amount: 16.99, date: '2024-06-15 09:00:00'},
    {id: '230', title: 'James Clarkson', status: 'paid', amount: 22.99, date: '2024-06-23 06:30:00'},

    {id: '2700', title: 'John Doe', status: 'paid', amount: 46.99, date: '2024-05-03 07:00:00'},
    {id: '2600', title: 'Bryan Williams', status: 'failed', amount: 53.99, date: '2024-05-03 00:01:00'},
    {id: '2500', title: 'Amber Brown', status: 'reversed', amount: 64.99, date: '2024-05-12 06:15:00'},
    {id: '2400', title: 'Emma Bridge', status: 'failed', amount: 72.99, date: '2024-05-12 02:00:00'},
    {id: '2300', title: 'James Clarkson', status: 'paid', amount: 46.99, date: '2024-05-25 09:00:00'},

    {id: '201', title: 'John Doe', status: 'paid', amount: 21.99, date: '2024-04-06 11:00:00'},
    {id: '191', title: 'Bryan Williams', status: 'failed', amount: 9.99, date: '2024-04-06 00:01:00'},
    {id: '181', title: 'Amber Brown', status: 'reversed', amount: 38.99, date: '2024-04-12 06:15:00'},
    {id: '171', title: 'Emma Bridge', status: 'refunded', amount: 49.99, date: '2024-04-12 06:00:00'},
    {id: '161', title: 'James Clarkson', status: 'paid', amount: 65.99, date: '2024-04-16 02:00:00'},
    {id: '151', title: 'Juliet Parks', status: 'paid', amount: 79.99, date: '2024-04-17 07:30:00'},

    {id: '13', title: 'John Doe', status: 'paid', amount: 45.99, date: '2024-03-03 09:00:00'},
    {id: '12', title: 'Amber Brown', status: 'reversed', amount: 31.99, date: '2024-03-03 00:01:00'},
    {id: '11', title: 'Emma Bridge', status: 'refunded', amount: 22.99, date: '2024-03-18 02:00:00'},
    {id: '10', title: 'James Clarkson', status: 'paid', amount: 58.99, date: '2024-03-19 06:45:00'},
    {id: '9', title: 'Juliet Parks', status: 'paid', amount: 16.99, date: '2024-03-19 11:01:00'},

    {id: '7', title: 'John Doe', status: 'paid', amount: 14.99, date: '2024-02-20 13:00:00'},
    {id: '5', title: 'Amber Brown', status: 'reversed', amount: 55.99, date: '2024-02-20 00:01:00'},
    {id: '4', title: 'Emma Bridge', status: 'failed', amount: 65.99, date: '2024-02-20 02:15:00'},
    {id: '3', title: 'James Clarkson', status: 'paid', amount: 22.99, date: '2024-02-20 09:00:00'},
    {id: '1', title: 'Selena Smith', status: 'reversed', amount: 22.99, date: '2024-02-03 09:15:00'},

    {id: '70', title: 'John Doe', status: 'paid', amount: 14.99, date: '2024-01-03 07:00:00'},
    {id: '50', title: 'Amber Brown', status: 'reversed', amount: 42.99, date: '2024-01-03 00:01:00'},
    {id: '40', title: 'Emma Bridge', status: 'failed', amount: 37.99, date: '2024-01-03 06:15:00'},
    {id: '30', title: 'James Clarkson', status: 'paid', amount: 73.99, date: '2024-01-16 02:30:00'},
    {id: '100', title: 'Selena Smith', status: 'reversed', amount: 11.99, date: '2024-01-22 02:45:00'},

    {id: '700', title: 'John Doe', status: 'paid', amount: 62.99, date: '2023-12-30 06:00:00'},
    {id: '500', title: 'Amber Brown', status: 'reversed', amount: 32.99, date: '2023-12-30 06:01:00'},
    {id: '400', title: 'Emma Bridge', status: 'failed', amount: 21.99, date: '2023-12-30 02:45:00'},
    {id: '300', title: 'James Clarkson', status: 'paid', amount: 11.99, date: '2023-12-30 11:15:00'},
    {id: '1000', title: 'Selena Smith', status: 'reversed', amount: 12.99, date: '2023-12-01 11:00:00'},

    {id: '7000', title: 'John Doe', status: 'paid', amount: 21.99, date: '2023-11-06 07:00:00'},
    {id: '5000', title: 'Amber Brown', status: 'reversed', amount: 32.99, date: '2023-11-06 00:01:00'},
    {id: '4000', title: 'Emma Bridge', status: 'failed', amount: 25.99, date: '2023-11-06 02:45:00'},
    {id: '3000', title: 'James Clarkson', status: 'paid', amount: 18.99, date: '2023-11-06 02:30:00'},
    {id: '10000', title: 'Selena Smith', status: 'reversed', amount: 34.99, date: '2023-11-07 02:00:00'},

    {id: '70000', title: 'John Doe', status: 'paid', amount: 10.99, date: '2023-10-13 09:00:00'},
    {id: '50000', title: 'Amber Brown', status: 'reversed', amount: 9.99, date: '2023-10-13 07:01:00'},
    {id: '40000', title: 'Emma Bridge', status: 'failed', amount: 12.99, date: '2023-10-13 07:00:00'},
    {id: '30000', title: 'James Clarkson', status: 'paid', amount: 13.99, date: '2023-10-13 02:15:00'},
    {id: '100000', title: 'Selena Smith', status: 'reversed', amount: 14.99, date: '2023-10-13 11:00:00'},

    {id: '700000', title: 'John Doe', status: 'paid', amount: 14.99, date: '2023-09-12 11:45:00'},
    {id: '500000', title: 'Amber Brown', status: 'reversed', amount: 73.99, date: '2023-09-12 00:01:00'},
    {id: '400000', title: 'Emma Bridge', status: 'failed', amount: 11.99, date: '2023-09-12 02:30:00'},
    {id: '300000', title: 'James Clarkson', status: 'paid', amount: 8.99, date: '2023-09-13 06:00:00'},
    {id: '1000000', title: 'Selena Smith', status: 'reversed', amount: 5.99, date: '2023-09-13 16:00:00'},

    {id: '7000000', title: 'John Doe', status: 'paid', amount: 28.99, date: '2023-08-12 09:00:00'},
    {id: '5000000', title: 'Amber Brown', status: 'reversed', amount: 8.99, date: '2023-08-12 06:01:00'},
    {id: '4000000', title: 'Emma Bridge', status: 'failed', amount: 26.99, date: '2023-08-12 02:15:00'},
    {id: '3000000', title: 'James Clarkson', status: 'paid', amount: 44.99, date: '2023-08-12 02:15:00'},
    {id: '10000000', title: 'Selena Smith', status: 'reversed', amount: 31.99, date: '2023-08-12 07:45:00'},
]

export const dummyPaymentChartData = (): ChartDataPoint[] => {

    const items: ChartDataPoint[] = [];

    mockPaymentData.map(item => ({
        x: item.date,
        y: item.amount
    })).forEach(item => {

        const month = moment(item.x).format('YYYY-MM').concat('-01');
        const groupIndex = items.findIndex(rec => rec.x == month);
        if (groupIndex < 0) {
            // not found
            items.push({x: month, y: item.y})
            return;
        }

        // found
        items[groupIndex] = {
            ...items[groupIndex],
            y: items[groupIndex].y + item.y
        }
    })

    return items
        .map(item => ({
            ...item,
            y: Math.round(item.y * 100) / 100
        }))
        .sort((a, b) => moment(a.x).diff(moment(b.x)));
}

const getAmountInMonth = (yyyy_mm: string) => {
    const amount = mockPaymentData.filter(item => moment(item.date).isSame(yyyy_mm, 'month'))
        .reduce((acc, current) => acc + current.amount, 0)

    return Math.round(amount * 100) / 100
}

const percentageChanged = (prevAmount: number, newAmount: number) => {
    return Math.round((((newAmount - prevAmount) / prevAmount) * 100) * 10) / 10;
}

export const dummyPaymentData = (start = '2024-06', end = '2024-07') => {
    const prevMonth = getAmountInMonth(start);
    const currentMonth = getAmountInMonth(end);
    return ({
        prevAmount: prevMonth,
        currentAmount: currentMonth,
        percentageChanged: percentageChanged(prevMonth, currentMonth)
    })
}

export const dummyRevenueData = (start = '2024-06', end = '2024-07') => {

    const months = moment(end).diff(start, 'months') + 1
    let amountFirstMonth = 0;
    let amountLastMonth = 0;
    let amount = 0

    for (let i = 0; i < months; i++) {
        const month = moment(start).add(i, 'month').format('YYYY-MM')
        const currentAmount = getAmountInMonth(month)

        if (i == 0) {
            amountFirstMonth = currentAmount;
        } else if (i == months - 1) {
            amountLastMonth = currentAmount;
        }

        amount += currentAmount
    }

    return ({
        revenue: amount,
        percentageChanged: percentageChanged(amountFirstMonth, amountLastMonth)
    })
}