import React, {Dispatch, FC, SetStateAction, useMemo} from 'react';
import {Tabs, TabsList, TabsTrigger} from "@src/@/components/ui/tabs";
import {classNames} from "@modules/casting/String";


type Props = {
    labels: string[] | undefined,
    tabState: [number, Dispatch<SetStateAction<number>>]
}

const PricingTabComponent: FC<Props> = ({labels, tabState}) => {

    const [currentTabIndex, setCurrentTabIndex] = useMemo(() => tabState, [tabState[0]]);

    const currentTab = useMemo(() => {
        return labels?.[currentTabIndex]
    }, [labels, currentTabIndex]);

    const handleChange = (label: string) => {
        console.log({label, labelIndex: labels?.findIndex(l => l == label)})
        if (labels) {
            setCurrentTabIndex(labels.findIndex(l => l == label))
        }

    }

    return (
        <Tabs
            defaultValue={currentTab}
            value={currentTab}
            onValueChange={handleChange}
            className={'bg-neutral-200 rounded-md mx-auto'}
        >
            <TabsList className={'grid grid-cols-3 w-full gap-x-4 h-auto items-center'}>
                {labels?.map((label, index) => <TabsTrigger
                    key={index}
                    value={label}
                    className={classNames(
                        'text-lg font-roboto font-medium',
                        currentTab === label && 'bg-primary-800 text-neutral-100'
                    )}
                >
                    {label}
                </TabsTrigger>)}
            </TabsList>
        </Tabs>

    );
};

export default PricingTabComponent;
