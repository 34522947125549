import React, {useEffect, useState} from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import OtpInputComponent from "@components/mfa/OtpInput.component";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import useAxios from "@hooks/useAxios";
import {useNavigate} from "react-router-dom";
import P from "@components/text/P.component";
import ErrorMessage from "@components/feedback/Message/ErrorMessage";

const MfaSetupVerifyOtpPage = () => {

    const axios = useAxios()
    const navigate = useNavigate()

    const [otp, setOtp] = useState<string>('')
    const [verifying, setVerifying] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    useEffect(() => {
        if (otp.length < 6) {
            return
        }

        setVerifying(true)
        const data = new FormData()
        data.append('otp', otp)

        axios.post<{
            msg: string
        }>('users/mfa/activate', data)
            .then(() => {
                setErrorMessage(undefined)
                navigate('success')
            })
            .catch(() => {
                setOtp('')
                setErrorMessage('Invalid code provided')
                setVerifying(false)
            })

    }, [otp]);

    return (
        <ShowPageTemplate>
            <P className={'text-center pt-8'}>Enter the 6 digit code you see in the authenticator app</P>
            <OtpInputComponent value={otp} onChange={setOtp} disabled={verifying} className={'py-8'}/>
            <ErrorMessage message={errorMessage}/>
            {verifying && <LoadingSpinner/>}
        </ShowPageTemplate>
    );
};

export default MfaSetupVerifyOtpPage;
