import React, {useEffect} from 'react';
import useGql from "@modules/graphql/useGql";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import Redirect from "@modules/routing/Redirect";

export const TjeckoSubscriptionRedirect = () => {
    const gql = useGql();

    const [route, setRoute] = React.useState('');

    useEffect(() => {
        gql.GetMerchantTjeckoSubStatus().then(result => {
            if (result.getMerchantTjeckoSubStatus.initialPaymentComplete) {
                setRoute('/checkout/success');
                return;
            }
            setRoute('/checkout/failed');
        }).catch(() =>
            setRoute('/checkout/failed'));
    }, []);

    return (
        route === ''
            ? <LoadingSpinner/>
            : <Redirect pathname={route}/>
    );
}