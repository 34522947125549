import React, {FC} from 'react';
import {ImplementFormPropsWithId} from "@components/form/Form.component";
import {UpdateBenefitInput, UpdateBenefitMutation} from "@src/gql-schema";
import {FormFields} from "@components/form/fields/FormField.component";
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import useGql from "@modules/graphql/useGql";

const BenefitUpdateForm: FC<ImplementFormPropsWithId<UpdateBenefitMutation> & {
    openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}> = ({
    id,
    onSubmitSuccess,
    openState
}) => {
    const gql = useGql();

    const fields: FormFields<UpdateBenefitInput> = {
        title: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Benefit Name',
            description: 'Name of your benefit'
        },
        description: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'textarea',
            label: 'Benefit description'
        }
    }

    return (
        <>
            <PullOverForm
                title={'Benefit update form'}

                openState={openState}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    id,
                    getData: async () => gql.GetBenefit({id}).then(r => r.benefit),
                    submit: gql.UpdateBenefit,
                    onSubmitSuccess: onSubmitSuccess,
                    fields: fields,
                    payloadInjection: {}
                }}
            />
        </>
    );
};

export default BenefitUpdateForm;
