import React, {FC} from 'react';
import PullOverAgreement, {ExtendPulloverAgreementProps} from "@components/pullover/PullOverAgreement.component";
import moment from "moment";

const CheckoutAgreement: FC<ExtendPulloverAgreementProps> = ({onSubmit, agreement, disabled}) => {

    return (
        <PullOverAgreement
            title={agreement?.title || 'Terms of Service'}

            trigger={{
                title: 'Proceed',
                disabled,
            }}
            submit={{
                title: 'Accept',
                onClick: onSubmit,
            }}
            cancel={{
                title: 'Decline',
            }}

            agreement={{
                title: 'ToS',
                description: moment(agreement?.updatedAt).format('YYYY-MM-DD'),
                children: agreement?.text ?? 'Loading...'
            }}
        />
    );
};

export default CheckoutAgreement;
