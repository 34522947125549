import React, {useMemo, useState} from 'react';
import PricingTabComponent from "@pages/merchant/plans/components/PricingTabComponent";
import PricingCardComponent, {PricingCard} from "@pages/merchant/plans/components/PricingCardComponent";
import PricingIntervalComponent, {PricingInterval} from "@pages/merchant/plans/components/PricingIntervalComponent";

import ShowPageTemplate from "@templates/page/ShowPage.template";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";

const PlansPage: React.FC = () => {
    const gql = useGql()

    const data = useAsyncMemo(async () => gql.GetTjeckoSubscription().then(r => r.tjeckoSubscription), [])

    const tabState = useState<number>(0)

    const intervals: PricingInterval[] | undefined = useMemo(() => {
        return data?.intervals?.map(interval => {

            const intervalType = interval.interval === 'monthly' ? 'monthly' : 'yearly'
            const discountType = interval.discountType === 'percentage' ? 'percentage' : 'fixed'

            return {
                id: interval.id,
                title: interval.interval,
                intervalType,
                discountType,
                discount: interval.discountAmount ?? 0,
                toolTip: interval.discountAmount ? `${interval.discountAmount}% off` : undefined
            }
        })

    }, [data]);

    const [currentInterval, setCurrentInterval] = useState(() => {
        return intervals?.find(interval => interval.intervalType === 'monthly') || intervals?.[0];
    });

    useMemo(() => {
        if (intervals && intervals.length > 0) {
            setCurrentInterval(intervals.find(interval => interval.intervalType === 'monthly') || intervals[0]);
        }
    }, [intervals]);

    const pricingFactor = useMemo(() => {

        switch (currentInterval?.intervalType) {
            case "monthly":
                return 1;
            case "yearly":
                return 12
            default:
                return 1
        }

    }, [currentInterval?.intervalType, data]);

    const getTierPrice = (price: number) => {

        const intervalPrice = price * pricingFactor;
        const discount = currentInterval?.discount;
        const discountType = currentInterval?.discountType;

        if (!discount || !discountType) {
            return intervalPrice
        }

        if (discountType == 'percentage') {
            const percentageToPay = 100 - discount;
            return parseFloat(`${(intervalPrice * (percentageToPay / 100)).toFixed(2)}`)
        }

        const discountedPrice = intervalPrice - discount;
        return discountedPrice < 0 ? 0 : discountedPrice

    }


    const pricingCards: PricingCard[] | undefined = useMemo(() => {

        return data?.tiers?.map(tier => {

            return {
                id: tier.id,
                title: tier.title,
                description: tier.description,
                price: getTierPrice(tier.price),
                usps: tier.TierHasBenefits.map(benefit => {
                    return {
                        title: benefit.title,
                        active: benefit.active
                    }
                }),
                isPopular: tier.title === 'Scale Up' && true
            }
        })

    }, [currentInterval, data]);

    const pricingLabels = useMemo(() => pricingCards?.map(card => card.title), [pricingCards]);

    return (
        <ShowPageTemplate
            hideAvatar={true}
            back={{
                title: 'Create Company',
                path: '/register/company'
            }}
        >

            {
                !data ? <div className={'absolute inset-0 flex justify-center items-center z-10'}>Loading ...</div>
                    :
                    <>
                        <div className={'py-4'}>
                            <PricingTabComponent
                                tabState={tabState}
                                labels={pricingLabels}
                            />
                        </div>

                        <div className={'pt-4 pb-6 px-12'}>
                            <PricingIntervalComponent
                                intervals={intervals}
                                intervalState={[currentInterval, setCurrentInterval]}
                            />
                        </div>

                        <PricingCardComponent
                            cards={pricingCards}
                            pricingInterval={currentInterval}
                            tabState={tabState}
                        />
                    </>
            }
        </ShowPageTemplate>);
};

export default PlansPage;
