import React from 'react';
import IndexPage from "../../../../templates/page/IndexPage.template";
import {SubscriberList} from "@pages/merchant/subscriber/index/components/SubscriberList";
import {CreateInviteLink} from "@pages/merchant/subscriber/index/components/CreateInviteLink";
import SubscriberChart from "@pages/merchant/subscriber/index/components/SubscriberChart";

const SubscriberIndexPage = () => {
    return (
        <IndexPage>
            <SubscriberChart/>
            <SubscriberList/>
            <CreateInviteLink/>
        </IndexPage>
    );
};

export default SubscriberIndexPage;
