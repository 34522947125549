import useAxios from "@hooks/useAxios";
import useGql from "@modules/graphql/useGql";


const useMollie = () => {

    const {get} = useAxios()
    const gql = useGql();

    /**
     * Get authorize url as string.
     */
    const authorizeUrl = async (): Promise<string | undefined> =>
        await get<{ authorize_url: string }>('mollie/authorize')
            .then(res => res.data.authorize_url);

    /**
     * Get boolean value isAuthorized
     */
    const isAuthorized = async () => {
        return await gql
            .IsAuthorizedByMollie({})
            .then(r => r.mollieToken);
    }

    return {
        authorizeUrl,
        isAuthorized
    }
};

export default useMollie;
