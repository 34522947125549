import React, {Dispatch, SetStateAction} from 'react';
import {FormControl, FormField, FormItem, FormLabel} from "@src/@/components/ui/form";
import {RadioGroup, RadioGroupItem} from "@src/@/components/ui/radio-group";
import {UseFormReturn} from "react-hook-form";
import {z} from "zod";
import {formSchema} from "@pages/merchant/checkout/index/CheckOut.index.page";
import {FormSchemaKeys} from "./CheckOutFormComponent";

interface InputRadioComponentProps {
    form: UseFormReturn<z.infer<typeof formSchema>>;
    options: string[];
    label: string;
    formName: FormSchemaKeys;
    selectedPaymentMethod: string;
    setSelectedPaymentMethod: Dispatch<SetStateAction<string>>
}

const InputRadioComponent: React.FC<InputRadioComponentProps> = ({
    form,
    formName,
    options,
    label,
    selectedPaymentMethod,
    setSelectedPaymentMethod
}) => {

    function handleClick(option: string) {
        setSelectedPaymentMethod(option)
    }


    return (
        <FormField control={form.control} name={formName} render={({field}) => {
            return (
                <FormItem>
                    <FormItem className={'space-y-0 flex flex-col gap-y-3'}>
                        <FormLabel>{label}</FormLabel>
                        <FormControl
                        >

                            <RadioGroup onValueChange={field.onChange} defaultValue={field.value}
                                className={'flex'}>
                                {
                                    options.map((option, index) => {
                                        return (
                                            <FormItem
                                                key={index}
                                                className={`flex items-center space-x-0 border rounded flex-grow justify-center 
                                                text-center space-y-0 ${selectedPaymentMethod === option ? 'bg-blue-200' : ''}`}>
                                                <FormControl>
                                                    <RadioGroupItem className={'hidden'} value={option}
                                                    />
                                                </FormControl>
                                                <FormLabel
                                                    className={'mt-0 text-base flex justify-center' +
                                                        ' py-1.5' +
                                                        ' w-full' +
                                                        ' text-center hover:cursor-pointer'}
                                                    onClick={() => handleClick(option)}>{option}</FormLabel>
                                            </FormItem>

                                        )
                                    })
                                }
                            </RadioGroup>
                        </FormControl>

                    </FormItem>
                </FormItem>
            )
        }}/>
    )
}

export default InputRadioComponent