import React, {createContext, FC, ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import useIdToken from "@modules/auth/useIdToken";
import useAxios from "@hooks/useAxios";
import useGql from "@modules/graphql/useGql";
import {MsgResponse} from "@app/entities/RestResponse.types";


type UseAuth = {
    userId: string | null,
    // Auth0 ID token is valid, null = pending validation
    auth0Authorized: boolean | null,
    // Tjecko backend accepts token, null = pending validation
    canMakeRequests: boolean | null,
    // Redirect to OTP, null = pending validation OR not logged in
    hasMfa: boolean | null,
    // OTP still valid, null = pending validation OR not logged in
    otpVerified: boolean | null,
    // Can make use of application, null = pending validation OR not logged in
    loggedIn: boolean | null,
    // Destroy identity
    logout: () => void
}


/**
 * Realtime auth check, only use in middleware, for application use cases use "useAuth"
 */
export const useAuthProvider = (): UseAuth => {

    const {isAuthenticated, isLoading, getIdTokenClaims, getAccessTokenSilently, logout} = useAuth0();
    const {idToken, setIdToken, destroyIdToken} = useIdToken();
    const axios = useAxios();
    const gql = useGql();

    const [userId, setUserId] = useState<string | null>(null)


    /**
     * Check if Auth0 authorizes user
     */
    const [auth0Authorized, setAuth0Authorized] = useState<boolean | null>(null)
    useEffect(() => {
        if (isLoading) {
            setAuth0Authorized(null)
            return
        }

        if (isAuthenticated) {
            setAuth0Authorized(true)
        }

        getIdTokenClaims().then(async (res) => {
            const token = res
                ? res.__raw
                : await getAccessTokenSilently().then(r => r)

            setIdToken(token);
            setAuth0Authorized(true)
        }).catch(() => {
            setAuth0Authorized(false)
        })

    }, [isLoading])


    /**
     * Call backend with intervals to validate token.
     */
    const [canMakeRequests, setCanMakeRequests] = useState<boolean | null>(null)
    const verifyTjeckoAccess = async (attempts = 3) => {
        axios.get<MsgResponse>('tjeckauth')
            .then(() => {
                setCanMakeRequests(true)
            })
            .catch(() => {
                if (attempts > 0) {
                    setTimeout(async () => {
                        await verifyTjeckoAccess(attempts - 1)
                    }, 5000)
                } else {
                    setCanMakeRequests(false)
                }
            })
    }

    /**
     * Check if token works in backend
     */
    useEffect(() => {
        if (!auth0Authorized) {
            setCanMakeRequests(auth0Authorized)
            return;
        }

        if (!idToken) {
            return;
        }

        verifyTjeckoAccess().then()
    }, [auth0Authorized, idToken]);

    /**
     * Check if user has MFA and OTP is valid
     */
    type Mfa = { hasMfa: boolean, otpVerified: boolean };
    const [mfa, setMfa] = useState<Mfa | null>(null)
    useEffect(() => {
        if (canMakeRequests === null) {
            setMfa(null)
            return;
        }
        if (!canMakeRequests) {
            setMfa({
                hasMfa: false,
                otpVerified: false
            })
            return;
        }

        gql.GetIdentities().then(res => {
            const user = res.identities.user;
            setUserId(user.id)
            setMfa({
                hasMfa: user.mfaEnabled,
                otpVerified: user.mfaVerified
            })
        })
    }, [canMakeRequests])

    /**
     * Check if user is authorized to use Tjecko
     */
    const loggedIn = useMemo(() => {
        if (canMakeRequests === null || mfa === null) {
            return null
        }
        const mfaValid = mfa.hasMfa ? mfa.otpVerified : true
        return canMakeRequests && mfaValid;
    }, [canMakeRequests, mfa]);

    /**
     * @todo api call to backend for logging out
     */
    const logoutInternal = () => {
        destroyIdToken()
        logout({
            logoutParams: {
                returnTo: `${window.location.origin}/login`
            }
        }).finally()
    }

    return {
        userId,
        auth0Authorized,
        canMakeRequests,
        hasMfa: mfa ? mfa.hasMfa : null,
        otpVerified: mfa ? mfa.otpVerified : null,
        loggedIn,
        logout: logoutInternal
    };
};


type AuthProviderProps = {
    children: ReactNode;
}

const initialState: UseAuth = {
    userId: null,
    auth0Authorized: null,
    canMakeRequests: null,
    hasMfa: null,
    otpVerified: null,
    loggedIn: null,
    logout: () => null
}

/**
 * Set token context.
 */
const AuthCtx = createContext<UseAuth>(initialState);


/**
 * Provider for application wide access.
 * @param children
 * @constructor
 */
export const AuthProvider: FC<AuthProviderProps> = ({children}) => {
    const auth = useAuthProvider();
    return (
        <AuthCtx.Provider value={auth}>
            {children}
        </AuthCtx.Provider>
    );
};


/**
 * Get up-to-date value application wide.
 */
const useAuth = () => useContext(AuthCtx);
export default useAuth
