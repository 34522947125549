import React, {useMemo} from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import {mockPaymentData,} from "@app/mock/MockPaymentData";
import useParamId from "@hooks/useParamId";
import {PaymentDetails} from "@pages/merchant/payment/show/components/PaymentDetails";
import {CustomerPaymentHistory} from "@pages/merchant/payment/show/components/CustomerPaymentHistory";

const PaymentShowPage = () => {

    const params = useParamId()

    const customer = useMemo(() => {
        const customerFinder = mockPaymentData.find(data => data.id === params)
        if (customerFinder) {
            return {
                ...customerFinder,
                symbol: customerFinder.title ? customerFinder?.title[0].concat(customerFinder?.title.split(' ')[1][0]) : undefined
            }
        }

        return undefined
    }, [params])

    const customerHistory = useMemo(() => {
        return mockPaymentData.filter(data => data.title === customer?.title)
    }, [])


    return (
        <ShowPageTemplate>
            <PaymentDetails customer={customer}/>
            <CustomerPaymentHistory customerHistory={customerHistory}/>
        </ShowPageTemplate>

    );
};

export default PaymentShowPage;
