import React, {FC, useEffect, useMemo, useState} from "react";
import GuestPageTemplate from "@templates/page/GuestPage.template";
import {TjeckoLogo} from "@components/icon/tjecko.icon";
import i18n from "i18next";
import P from "@components/text/P.component";
import OtpInputComponent from "@components/mfa/OtpInput.component";
import ErrorMessage from "@components/feedback/Message/ErrorMessage";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import useAxios from "@hooks/useAxios";
import PrimaryButton from "@components/button/PrimaryButton";
import useAuth from "@hooks/useAuth";
import Redirect from "@modules/routing/Redirect";

export const OtpPage: FC = () => {

    const axios = useAxios()
    const auth = useAuth()

    const [otp, setOtp] = useState<string>('');
    const [verifying, setVerifying] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        if (auth.loggedIn === true) {
            setIsVerified(true)
        }
    }, [auth.loggedIn]);


    const handleSubmit = async () => {
        setVerifying(true)
        const data = new FormData()
        data.append('otp', otp)

        axios.post<{
            msg: string
        }>('users/mfa', data)
            .then(() => {
                setErrorMessage(undefined)
                setIsVerified(true)
            })
            .catch(() => {
                setOtp('')
                setErrorMessage('Invalid code provided')
                setVerifying(false)
                setIsVerified(false)
            })
    }

    const redirectOnVerified = useMemo(() => {
        return isVerified ? '/' : null
    }, [isVerified]);

    const [prevOtp, setPrevOtp] = useState(otp)
    useEffect(() => {
        setPrevOtp(otp)
        if (otp.length == 6 && prevOtp.length < 5) {
            handleSubmit().then()
        }
    }, [otp]);


    return <GuestPageTemplate>
        <div className={'text-center grid grid-cols-1 gap-y-4 translate-y-[calc(50vh-18rem)]'}>
            <TjeckoLogo/>
            <P className={'text-center pt-8'}>{i18n.t('otp.text.verify')}</P>
            <OtpInputComponent value={otp} onChange={setOtp} disabled={verifying} className={'py-8'}/>

            <ErrorMessage message={errorMessage}/>
            <PrimaryButton onClick={handleSubmit} disabled={otp.length < 6 || verifying}>Submit</PrimaryButton>

            {verifying && <LoadingSpinner/>}

            {redirectOnVerified && <Redirect pathname={redirectOnVerified}/>}
        </div>
    </GuestPageTemplate>
}
