import React from 'react';
import ListPage from "@templates/page/ListPage.template";
import {CustomerCardList} from "@pages/merchant/subscriber/show/components/CustomerCardList";
import {customer} from "@pages/merchant/subscriber/show/Subscriber.show.page";

export function SubscriberInvoiceList() {
    return (
        <ListPage>
            <CustomerCardList customer={customer}/>
        </ListPage>
    )
}