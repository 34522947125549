import React from 'react';
import IndexPage from "@templates/page/IndexPage.template";
import useGql from "@modules/graphql/useGql";
import SubscriptionSearchBar from "@pages/merchant/subscriptions/index/components/SubscriptionSearchBar";
import SubscriptionCard from "@pages/merchant/subscriptions/index/components/SubscriptionCard";
import useAsyncMemo from "@hooks/useAsyncMemo";
import ArchiveButton from "@components/button/ArchiveButton";

const SubscriptionIndexPage = () => {

    const {GetSubscriptions} = useGql()

    const subscriptions = useAsyncMemo(async () => (
        await GetSubscriptions().then(r => r.subscriptions ?? [])
    ), [], [])

    return (
        <IndexPage navChildren={<SubscriptionSearchBar/>}>

            {subscriptions.map(subscription =>
                <SubscriptionCard
                    key={subscription.id}
                    id={subscription.id}
                    title={subscription.title}
                    description={subscription.description}
                    revenue={19.95}
                    activeSubscribers={12}
                />
            )}

            <ArchiveButton/>
        </IndexPage>
    );
};

export default SubscriptionIndexPage;
