import React, {useEffect, useRef, useState} from 'react';
import useGql from "@modules/graphql/useGql";
import {FormFields} from "@components/form/fields/FormField.component";
import {
    GetCurrentMerchantQuery,
    MerchantRegistrationInput,
    RegisterMerchantMutation,
} from "@src/gql-schema";
import {z} from "zod";
import ShowPageTemplate from "@templates/page/ShowPage.template";
import Form from "@components/form/Form.component";
import PrimaryButton from "@components/button/PrimaryButton";
import CompanyIcon from "@components/icon/CompanyIcon";
import {GraphQLError} from "graphql/error";

const CompanyRegisterPage = () => {
    const [saveData, setSaveData] = useState<GetCurrentMerchantQuery>()

    const gql = useGql();
    const formRef = useRef<HTMLFormElement>(null)

    const fields: FormFields<MerchantRegistrationInput> = {
        name: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            defaultValue: saveData?.getCurrentMerchant?.name,
            label: 'Company name',
        },
        coc: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            defaultValue: saveData?.getCurrentMerchant?.cocInfo?.coc,
            label: 'COC Number',
        },
        vat: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            defaultValue: saveData?.getCurrentMerchant?.cocInfo?.vat,
            label: 'VAT ID',
        },
        address: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Billing address',
            defaultValue: saveData?.getCurrentMerchant?.addresses?.[0].address,
        },
        extra: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            defaultValue: saveData?.getCurrentMerchant?.addresses?.[0].extra,
            label: 'Extra',
        },
        postalCode: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            defaultValue: saveData?.getCurrentMerchant?.addresses?.[0].postalCode,
            label: 'Zipcode',
        },
        city: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            defaultValue: saveData?.getCurrentMerchant?.addresses?.[0].city,
            label: 'City',
        }

    }

    const onSubmitSuccess = (t: RegisterMerchantMutation) => {
        console.log({t})
    }

    useEffect(() => {
        // When user submitted the form, and it was a success then a merchant will be attached/linked to the login email
        gql.GetCurrentMerchant().then(r => setSaveData(r)).catch((err: GraphQLError) => {
            console.log('No Saved Data')
            return console.error(err.message)
        })
    }, []);

    return (
        <ShowPageTemplate>
            <div className={'rounded-full p-2 border border-neutral-500 mx-auto w-fit mt-8'}>
                <CompanyIcon/>
            </div>
            <Form
                ref={formRef}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                submit={gql.RegisterMerchant}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                onSubmitSuccess={onSubmitSuccess}
                fields={fields}
                payloadInjection={{}}
            />

            <PrimaryButton className={'mt-4'} onClick={() => formRef.current?.requestSubmit()}>Submit</PrimaryButton>
        </ShowPageTemplate>
    )
};
export default CompanyRegisterPage;
