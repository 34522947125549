import React, {useState} from 'react';
import ShowPageTemplate from "@templates/page/ShowPage.template";
import OtpInputComponent from "@components/mfa/OtpInput.component";
import LoadingSpinner from "@components/feedback/LoadingSpinner.component";
import useAxios from "@hooks/useAxios";
import {useNavigate} from "react-router-dom";
import P from "@components/text/P.component";
import NegativeButton from "@components/button/NegativeButton";
import i18n from "i18next";
import ErrorMessage from "@components/feedback/Message/ErrorMessage";

const MfaDeactivatePage = () => {

    const axios = useAxios()
    const navigate = useNavigate()

    const [otp, setOtp] = useState<string>('')
    const [verifying, setVerifying] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()


    const handleDeactivate = async () => {
        setVerifying(true)
        const data = new FormData()
        data.append('otp', otp)

        axios.post<{
            msg: string
        }>('users/mfa/deactivate', data)
            .then(() => {
                setErrorMessage(undefined)
                navigate('success')
            })
            .catch(() => {
                setOtp('')
                setErrorMessage('Invalid code provided')
                setVerifying(false)
            })
    }

    return (
        <ShowPageTemplate>
            <P className={'text-center pt-8'}>{i18n.t('otp.text.verify')}</P>
            <OtpInputComponent value={otp} onChange={setOtp} disabled={verifying} className={'py-8'}/>
            <ErrorMessage message={errorMessage}/>
            <NegativeButton onClick={handleDeactivate} disabled={otp.length < 6 || verifying}>Deactivate
                MFA</NegativeButton>
            {verifying && <LoadingSpinner/>}
        </ShowPageTemplate>
    );
}
    ;

export default MfaDeactivatePage;
