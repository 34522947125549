import React from 'react';
import {PaymentCustomerDetails} from "@pages/merchant/subscriber/show/components/PaymentCustomerDetails";
import {PaymentTab} from "@pages/merchant/subscriber/show/components/PaymentTab";
import ShowPageTemplate from "@templates/page/ShowPage.template";


// Can be seen in /temp route

// Sample Customer Data

export type Customer = {
    name: string
    age: number
    subscription: {
        name: string;
        tier: string;
        price: string;
    }[],
    memberSince: string;
    totalSpent: string;
    balance: string
    paymentMethods: {
        name: string;
        cardNum?: string;
        expired?: string;
        isDefault: boolean;
    }[],
    isActive: boolean;
    nextInvoice: { date: string }
}

export const customer: Customer = {
    name: 'John Doe',
    age: 23,
    subscription: [
        {
            name: 'Great Service',
            tier: 'Basic',
            price: '€30'
        }
    ],
    memberSince: '11 November 2023',
    totalSpent: '€1.563,00',
    balance: '€600',
    paymentMethods: [
        {
            name: 'VISA', // + Icon
            cardNum: '*********67836',
            expired: 'Expired Oct 2025',
            isDefault: true
        },
        {
            name: 'Paypal',
            isDefault: false
        }
    ],
    isActive: true,
    nextInvoice: {
        date: 'Jun 01 2024',
    }
}

const SubscriberShowPage = () => {

    return <ShowPageTemplate>
        <PaymentCustomerDetails customer={customer}/>
        <PaymentTab customer={customer}/>
    </ShowPageTemplate>
};

export default SubscriberShowPage;
