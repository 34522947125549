import PaymentListComponent from "@pages/merchant/payment/components/PaymentListComponent";
import React, {useMemo} from "react";
import {PaymentItem} from "@app/mock/MockPaymentData";
import useWorkspaceHeight from "@hooks/useWorkspaceHeight";

interface CustomerPaymentHistoryProps {
    customerHistory: PaymentItem[]
}

export function CustomerPaymentHistory({customerHistory}: CustomerPaymentHistoryProps) {

    const {available} = useWorkspaceHeight()

    const listHeight = useMemo(() => {
        return `calc(${available - 230.5 - 41 - 30}px - 4.0625rem)`
    }, [available]);

    return (
        <div className={'-m-shell'}>
            <PaymentListComponent items={customerHistory} height={listHeight}/>
        </div>
    )
}