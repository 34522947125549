import React, {FC} from 'react';
import {z} from "zod";
import PullOverForm from "@components/pullover/PullOverForm.component";
import {ImplementFormProps} from "@components/form/Form.component";
import {CreateCompanyInput, CreateCompanyMutation} from "@src/gql-schema";
import {FormFields} from "@components/form/fields/FormField.component";
import useGql from "@modules/graphql/useGql";

const CompanyCreateTestForm: FC<ImplementFormProps<CreateCompanyMutation>> = ({
    onSubmitSuccess
}) => {

    const gql = useGql()


    const formFields: FormFields<CreateCompanyInput> = {
        name: {
            zodType: z.string().min(1).max(64),
            fieldType: 'input',
            label: 'Company Name',
            description: 'Name of your company'
        },
        invoiceEmail: {
            zodType: z.string().min(1).max(64).email(),
            fieldType: 'input',
            label: 'Invoice mail',
            description: 'Mail where invoice is sent to'
        },
        invoiceRecipient: {
            zodType: z.string().min(1).max(64).optional(),
            fieldType: 'input',
            label: 'Invoice Recipient',
            description: 'Name of invoice recipient'
        }
    }

    return (
        <>
            <PullOverForm
                title={'Company test form'}
                trigger={{
                    title: 'Create'
                }}
                submit={{
                    title: 'Save'
                }}
                cancel={{
                    title: 'Cancel'
                }}
                form={{
                    submit: gql.CreateCompany,
                    onSubmitSuccess: onSubmitSuccess,
                    fields: formFields,
                    payloadInjection: {}
                }}
            />
        </>
    );
};

export default CompanyCreateTestForm;
