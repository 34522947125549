import React, {FC, useState} from 'react';
import CreateButton from "@components/button/CreateButton";
import ArchiveButton from "@components/button/ArchiveButton";
import useGql from "@modules/graphql/useGql";
import useAsyncMemo from "@hooks/useAsyncMemo";
import BenefitUpdateForm from "@pages/merchant/tiers/components/BenefitUpdate.form";
import BenefitCreateForm from "@pages/merchant/tiers/components/BenefitCreate.form";
import {CreateBenefitMutation} from "@src/gql-schema";
import BenefitCard from "@pages/merchant/tiers/components/BenefitCard";
import SearchBar from "@components/search/SearchBar.component";
import H2 from "@components/text/H2.component";


type Props = {
    tierId: string
    subscriptionId?: string
}

const BenefitsSection: FC<Props> = ({
    tierId,
    subscriptionId
}) => {

    const gql = useGql()

    const [currentBenefitId, setCurrentBenefitId] = useState<string>()
    const [openBenefitCreate, setOpenBenefitCreate] = useState(false)
    const [openBenefitEdit, setOpenBenefitEdit] = useState(false)

    /**
     * All benefits of subscription
     */
    const [updatedAllBenefits, setUpdateAllBenefits] = useState(0)
    const allBenefits = useAsyncMemo(async () => {
        if (!subscriptionId) {
            return []
        }
        return await gql
            .GetBenefitsBySubscriptionId({subscriptionId: subscriptionId})
            .then(r => r.benefitsBySubscriptionId ?? [])

    }, [subscriptionId, updatedAllBenefits], [])

    /**
     * All benefits of tier
     */
    const [updatedTierBenefits, setUpdateTierBenefits] = useState(0)
    const tierBenefits = useAsyncMemo(async () => {
        if (allBenefits.length == 0) {
            return []
        }
        return await gql.GetBenefitsByTierId({tierId: tierId})
            .then(r => r.benefitsByTierId ?? [])
    }, [updatedTierBenefits, allBenefits], [])

    /**
     * Refresh tier benefits only
     */
    const refreshTierBenefits = () => setUpdateTierBenefits(updatedTierBenefits + 1)

    /**
     * Refresh subscription + tier benefits
     */
    const refreshAllBenefits = () => {
        setUpdateAllBenefits(updatedAllBenefits + 1)
    }

    /**
     * Check if subscription benefit is present as tier benefit
     * @param benefitId
     */
    const isActive = (benefitId: string) => (
        tierBenefits.find(b => b.id == benefitId) !== undefined
    )

    /**
     * Create tier benefit pivot
     *
     * @param benefitId
     */
    const addBenefitToTier = async (benefitId?: string) => {
        if (!benefitId) {
            return;
        }
        await gql.AddBenefitToTier({
            tierId: tierId,
            benefitId: benefitId
        }).then(() => {
            const benefitCreated = allBenefits.find(b => b.id == benefitId) == undefined;
            if (benefitCreated) {
                refreshAllBenefits()
            } else {
                refreshTierBenefits()
            }
        })
    }

    /**
     * Remove tier benefit pivot
     * @param benefitId
     */
    const removeBenefitFromTier = async (benefitId?: string) => {
        if (!benefitId) {
            return;
        }
        await gql.RemoveBenefitForTier({
            tierId: tierId,
            benefitId: benefitId
        }).then(refreshTierBenefits)
    }

    /**
     * (De)activate tier benefit.
     *
     * @param benefitId
     */
    const handleActive = async (benefitId: string) => {
        if (isActive(benefitId)) {
            await removeBenefitFromTier(benefitId)
            return
        }
        await addBenefitToTier(benefitId);
    }

    /**
     * Init benefit creation
     */
    const handleCreate = () => {
        setOpenBenefitEdit(false)
        setCurrentBenefitId(undefined);
        setOpenBenefitCreate(true)
    }

    /**
     * Refresh benefit data on create.
     *
     * @param benefit
     */
    const handleCreated = async (benefit?: CreateBenefitMutation) => {
        await addBenefitToTier(benefit?.createBenefit.id)
            .then(() => setCurrentBenefitId(undefined))
    }

    /**
     * Init benefit update.
     *
     * @param benefitId
     */
    const handleEdit = (benefitId: string) => {
        setCurrentBenefitId(benefitId)
        setOpenBenefitCreate(false)
        setOpenBenefitEdit(true)
    }

    /**
     * Refresh benefits after update.
     */
    const handleUpdated = () => {
        refreshAllBenefits()
    }

    return (
        <div className={'bg-neutral-300/65 -mx-shell px-shell pt-shell'}>
            <H2>Benefits</H2>
            <SearchBar
                className={'mb-shell'}
                inputProps={{placeholder: 'Find Tier'}}
                button={<CreateButton onClick={handleCreate}/>}
            />
            <div className={'h-[calc(100vh-23.704rem)] overflow-y-auto -mx-shell px-shell -mb-shell pb-shell'}>
                <div className={'grid grid-cols-1 gap-y-2.5'}>
                    {allBenefits.map(benefit => <BenefitCard
                        key={benefit.id}
                        id={benefit.id}
                        title={benefit.title}
                        description={benefit.description}
                        active={isActive(benefit.id)}
                        handleEdit={handleEdit}
                        handleActive={handleActive}
                    />)}
                    <ArchiveButton/>
                </div>
            </div>

            {subscriptionId && <BenefitCreateForm
                subscriptionId={subscriptionId}
                tierId={tierId}
                openState={[openBenefitCreate, setOpenBenefitCreate]}
                onSubmitSuccess={handleCreated}
            />}

            {currentBenefitId && <BenefitUpdateForm
                id={currentBenefitId}
                openState={[openBenefitEdit, setOpenBenefitEdit]}
                onSubmitSuccess={handleUpdated}
            />}
        </div>
    );
};

export default BenefitsSection;
